import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function Result() {
  const { t } = useTranslation();
  const location = useLocation();

  const [results, setresults] = React.useState({});
  React.useEffect(() => {
    if (location.state) {
      setresults({...location.state.data, number:location.state.number});
  
    }
  }, []);
  return (
    <div>
      <div className="row">
        <div className="flex-between withdraws__card titled">
          <div className="number flex-center">
            <img
              className="hidden-desktop"
              src={`${process.env.PUBLIC_URL}/img/midal2.svg`}
              alt=""
            />
            <img
              className="hidden-mobile"
              src={`${process.env.PUBLIC_URL}/img/midal.svg`}
              alt=""
            />
          </div>
          <div className="content">
            <h1>{results.DrawName}</h1>
            <p>
              {" "}
              {t("phase5.whithdraw.title")} {results.number} {results.DrawDate}
            </p>
          </div>
        </div>
        {results.WinnerDetails?.map((e, i) => (
          <div className="flex-between withdraws__card sub">
            <div className="number flex-center">{i + 1}</div>
            <div className="content">
              <h1>{e.WinnerName}</h1>
              <p>{e.WinnerInfo}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
