import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import i18Next from "i18next";

import Select from "../../components/Select";
import EmailInput from "../../components/EmailInput";
import PasswordInput from "../../components/PasswordInput";
import { GetCountries, GetCities } from "../../actions/generalActions";
import { RegisterCompany } from "../../actions/authActions";
import NumberInput from "../../components/NumberInput";
import Loading from "../../components/Loading";

export default function Rigester(props) {
  const [text, setText] = React.useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [registerSubmitInfo, setregisterSubmitInfo] = React.useState({
    loading: false,
    error: "",
  });
  const [formData, setformData] = React.useState({
    CorporateNameEN: "",
    CorporateNameAR: "",
    Type: 1,
    CountryId: 0,
    CityId: 0,
    CRNumber: "",
    ChamberMembershipId: "",
    EmailId: "",
    PhoneNumber: "",
    Password: "11",
  });
  const [countries, setcountries] = React.useState({
    loading: false,
    list: [],
  });
  const [cities, setcities] = React.useState({
    loading: false,
    list: [],
  });
  const types = [
    { value: 1, label: `${t("forms.company")}` },
    { value: 2, label: `${t("forms.agency")}` },
  ];
  const callDropdownLists = () => {
    //call countries
    setcountries({ ...countries, loading: true });

    setcities({ ...cities, list: [] });

    dispatch(GetCountries())
      .then((e) => {
        setcountries({ list: e, loading: false });
      })
      .catch((err) => {
        setcountries({ list: [], loading: false });
      });
  };
  const findInArr = (arr = [], value, property) => {
    let ind = arr.findIndex((e) => e[property] === value);
    return ind !== -1 ? arr[ind] : [];
  };
  //events

  const onRegisterSubmit = (e) => {
    if (!registerSubmitInfo.loading) {
      setregisterSubmitInfo({ ...registerSubmitInfo, loading: true });
      dispatch(RegisterCompany({ ...formData }))
        .then((res) => {
          setregisterSubmitInfo({ error: "", loading: false });

          if (res.Status) {
            setregisterSubmitInfo({ error: "", loading: false });
            props.history.push({
              pathname: "/web/auth/loginCompany",
            });
          } else {
            setregisterSubmitInfo({ error: res.Message, loading: false });
          }
        })
        .catch((err) => {
          setregisterSubmitInfo({ error: t("forms.error"), loading: false });
        });
    }
  };
  const onCountryChange = (e) => {
    setcities({ ...cities, loading: true });
    updateFormData(e.CountryID, "CountryId");

    // call first drop down
    dispatch(GetCities(e.CountryID))
      .then((res) => {
        setcities({ list: res, loading: false });
      })
      .catch((err) => {
        setcities({ list: [], loading: false });
      });
  };
  const updateFormData = (value, property) => {
    let nwData = { ...formData };
    nwData[property] = value;
    setformData(nwData);
  };
  const options = [
    { value: "1", label: "فرغ" },
    { value: "2", label: "السحب الثاني" },
    { value: "3", label: "السحب الثالث" },
    { value: "4", label: "السحب الرابع" },

    { value: "5", label: "السحب الخامس" },
  ];
  React.useEffect(() => {
    callDropdownLists();
  }, [i18Next.languages]);
  return (
    <div className="phone-form">
      <h1 className="auth__heading">{t("auth.heading")}</h1>
      <h1 className="auth__title">{t("auth.create")}</h1>
      <h3 className="auth__subtitle">{t("auth.new_account")}</h3>

      <div className="auth__box">
        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="col-1-of-2 col-padding-small pos-rel">
            <input
                value={formData.CorporateNameAR}
                onChange={(e) => {
                  updateFormData(e.target.value, "CorporateNameAR");
                }}
                type="text"
                className={`form__input  circle `}
                placeholder={t("placeholders.company-name-ar")}
              />
            </div>
            <div className="col-1-of-2 col-padding-small pos-rel">
            <input
                value={formData.CorporateNameEN}
                onChange={(e) => {
                  updateFormData(e.target.value, "CorporateNameEN");
                }}
                type="text"
                className={`form__input  circle `}
                placeholder={t("placeholders.company-name")}
              />
            
            </div>
          </div>
        </div>
        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="pos-rel">
              <Select
                className="filter-select in-form "
                classNamePrefix="select"
                value={findInArr(types, formData.Type, "value")}
                onChange={(e) => {
                  updateFormData(parseInt(e.value), "Type");
                }}
                placeholder={t("placeholders.type")}
                name="type"
                options={types}
              />
            </div>
          </div>
        </div>

        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="col-1-of-2 col-padding-small pos-rel">
              <Select
                className="filter-select in-form"
                classNamePrefix="select"
                placeholder={t("placeholders.country")}
                name="country"
                onChange={onCountryChange}
                isSearchable={true}
                isLoading={countries.loading}
                options={countries.list}
                getOptionLabel={(option) => option.CountryName}
                getOptionValue={(option) => option.CountryID}
                value={findInArr(
                  countries.list,
                  formData.CountryId,
                  "CountryID"
                )}
              />
            </div>
            <div className="col-1-of-2 col-padding-small pos-rel">
              <Select
                className="filter-select in-form"
                classNamePrefix="select"
                placeholder={t("placeholders.city")}
                name="city"
                onChange={(e) => {
                  updateFormData(e.CityID, "CityId");
                }}
                isSearchable={true}
                isLoading={cities.loading}
                options={cities.list}
                getOptionLabel={(option) => option.CityName}
                getOptionValue={(option) => option.CityID}
                value={findInArr(cities.list, formData.CityId, "CityID")}
              />
            </div>
          </div>
        </div>

        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="col-1-of-2 col-padding-small pos-rel">
              <input
                value={formData.ChamberMembershipId}
                onChange={(e) => {
                  updateFormData(e.target.value, "ChamberMembershipId");
                }}
                type={"text"}
                className={`form__input  circle `}
                placeholder={t("placeholders.trade_member")}
              />
            </div>
            <div className="col-1-of-2 col-padding-small pos-rel">
              <input
                value={formData.CRNumber}
                onChange={(e) => {
                  updateFormData(e.target.value, "CRNumber");
                }}
                type={"text"}
                className={`form__input  circle `}
                placeholder={t("placeholders.trade_id")}
              />
            </div>
          </div>
        </div>
        <div className="form__group big-btm">
          <div className="row row--no-padding">
            <div className="col-1-of-2 col-padding-small pos-rel">
              <NumberInput
                placeholder={t("placeholders.phone")}
                changeEvent={(e) => {
                  updateFormData(e, "PhoneNumber");
                }}
                value={formData.PhoneNumber}
              />
            </div>
            <div className="col-1-of-2 col-padding-small pos-rel">
              <EmailInput
                changeEvent={(e) => {
                  updateFormData(e, "EmailId");
                }}
                value={formData.EmailId}
                placeholder={t("placeholders.email")}
              />
            </div>
          </div>
        </div>
        {/* <div className="form__group big-btm">
          <div className="row row--no-padding">
            <div className=" pos-rel">
              <PasswordInput
                onChange={(e) => {
                  updateFormData(e.target.value, "Password");
                }}
                value={formData.Password}
                placeholder={t("placeholders.passord")}
              />
            </div>
          </div>
        </div> */}

        <div className="form__group ">
          {registerSubmitInfo.error && (
            <div className="mg-btm">
              <span className="error-text">{registerSubmitInfo.error}</span>
            </div>
          )}
          <a
            onClick={onRegisterSubmit}
            className={` btn bold custom-padding ${
              registerSubmitInfo.loading ? "btn--disabled" : "btn--green"
            } w-100`}
          >
            {registerSubmitInfo.loading ? (
              <Loading white smMargin />
            ) : (
              t("placeholders.next")
            )}{" "}
          </a>
        </div>
      </div>
    </div>
  );
}
