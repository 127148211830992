import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Table from "../../components/Table";
import Select from "../../components/Select";
import i18next from "i18next";
import {
  GetSiteCompetitionNamesForDropDown,
  GetWinnerDrawListForDropDown,
  GetWinnerByFilters
} from "../../actions/generalActions";
import Loading from "../../components/Loading";


export default function Winners() {
  const [compsDropDown, setcompsDropDown] = React.useState({
    loading: false,
    list: [],
  });
  const [drawsDropDown, setdrawsDropDown] = React.useState({
    loading: false,
    list: [],
  });
  const [winners, setwinners] = React.useState({
    loading: false,
    list: [],
  });
  const [searchData, setsearchData] = React.useState({
    comp: null,
    draw: null,
  });
  const [empty, setempty] = React.useState(false);
  const options = [
    { value: "1", label: "فرغ" },
    { value: "2", label: "السحب الثاني" },
    { value: "3", label: "السحب الثالث" },
    { value: "4", label: "السحب الرابع" },

    { value: "5", label: "السحب الخامس" },
  ];
  const dispatch = useDispatch();
 const onSearch =(e)=>{
  setwinners({ ...winners, loading: true });
 
    // call winners list
    dispatch(GetWinnerByFilters(searchData.comp?.ECouponID,searchData.draw?.ECouponDrawID))
      .then((res) => {
        res.Winner.sort((a, b) => a.ConsumerName.localeCompare(b.ConsumerName))
        setwinners({ list: res.Winner, loading: false });
        
      })
      .catch((err) => {
        setwinners({ list: [], loading: false });
      });
  }
  const onChange = (e) => {
    setdrawsDropDown({ ...drawsDropDown, loading: true });
    setsearchData({ draw: null, comp: e });

    // call first drop down
    dispatch(GetWinnerDrawListForDropDown(e.ECouponID))
      .then((res) => {
        setdrawsDropDown({ list: res, loading: false });
      })
      .catch((err) => {
        setdrawsDropDown({ list: [], loading: false });
      });
  };
  const onDrawChange = (e) => {
    setsearchData({ ...searchData, draw: e });
  };
  const { t } = useTranslation();

  const columns = [
    { name: "#", customName: `${t("winners.table.id")}`,valueProperty:'id' , id:true},
    { name: `${t("winners.table.name")}` ,valueProperty:'ConsumerName'},
    { name: `${t("winners.table.place")}`,valueProperty:'Location' },
    { name: `${t("winners.table.award")}`,valueProperty:'PrizeDetail' },

    { name: `${t("winners.table.date")}`,valueProperty:'DrawDateTime', date:true },
  ];
  // data of the table
  const data = [
    {
      "#": "1",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "2",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "3",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "4",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "5",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "6",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "7",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "8",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "9",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
    {
      "#": "10",
      [t("winners.table.name")]: "ABDALGHANE MASOUDI",
      [t("winners.table.place")]: "--------------",
      [t("winners.table.award")]: "كاش",
      [t("winners.table.date")]: "28/1/2021",
    },
  ];

  React.useEffect(() => {
    setcompsDropDown({ ...compsDropDown, loading: true });
    // setsearchData({ comp: null, draw: null });
    setdrawsDropDown({ ...drawsDropDown, list: [] });

    // call first drop down
    dispatch(GetSiteCompetitionNamesForDropDown())
      .then((e) => {
        setcompsDropDown({ list: e, loading: false });
      })
      .catch((err) => {
        setcompsDropDown({ list: [], loading: false });
      });
  }, [i18next.languages]);

  return (
    <div className="winners">
      <div className="row">
        <div className="winners__header">
          <div className="row row--no-padding">
            <div className="winners__header--content">
              <h1 className="page-title">{t("winners.title")}</h1>
              <h3 className="page-sub">{t("winners.sub")}</h3>
            </div>
          </div>
        </div>
        <div className="winners__wrapper">
          <div className="winners__filter">
            <div>
              <h6>{t("winners.select_title")}</h6>
              <Select
                className="filter-select"
                classNamePrefix="select"
                placeholder={t("winners.placeholder")}
                name="questions"
                onChange={onChange}
                isSearchable={true}
                isLoading={compsDropDown.loading}
                options={compsDropDown.list}
                getOptionLabel={(option) => option.EService}
                getOptionValue={(option) => option.ECouponID}
                value={searchData.comp}
              />
            </div>
            <div>
              <h6>{t("winners.select_title")}</h6>
              <Select
                className="filter-select"
                classNamePrefix="select"
                placeholder={t("winners.placeholder1")}
                name="competetions"
                isSearchable={true}
                isLoading={drawsDropDown.loading}
                options={drawsDropDown.list}
                onChange={onDrawChange}
                getOptionLabel={(option) => option.Name}
                getOptionValue={(option) => option.ECouponDrawID}
                value={searchData.draw}
              />
            </div>
            <div className="filter-btns">
              {!searchData.comp || !searchData.draw ? (
                <button
                  disabled
                  className="btn btn--disabled has-md-radius  middle big-padding"
                >
                  {t("winners.search")}
                </button>
              ) : (
                <a 
                onClick={onSearch}
                className="btn btn--green middle big-padding has-md-radius">
                  {t("winners.search")}
                </a>
              )}
              {searchData.comp && searchData.draw && (
                <a
                  onClick={() => setsearchData({ comp: null, draw: null })}
                  className="btn btn--red middle has-icon has-md-radius fixed-width no-hover"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/img/close.svg`}
                    alt="close filters"
                  />
                  <span>{t("winners.result")}</span>
                </a>
              )}
            </div>
          </div>
          <div className="winners__table">
            <Table empty={empty} columns={columns} data={winners} />
          </div>
        </div>
      </div>
    </div>
  );
}
