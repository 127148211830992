import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../components/Navbar";
import Hero from "../components/Hero";

import { Route, Switch, Redirect } from "react-router-dom";
import CustomHero from "../components/CustomHero";
import ProtectedRoute from "../helpers/ProtectedRoute";


export default withRouter(function Main({ routes, children, location }) {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar routes={routes} />
      {location.pathname.includes("/web/main/home")? (
        <CustomHero routes={routes} />
      ) : (
        <Hero routes={routes} />
      )}
      <div className="page-body">
        <Switch>
          {routes.map((prop, key) => {
            return (
              prop.protected ? 
             
              <ProtectedRoute
              path={prop.layout + prop.path}
              title={`${prop.rtlName}`}
              component={prop.component }
             
              key={key}
            />
              :

              <Route
                path={prop.layout + prop.path}
                render={(props) => (
                  <prop.component {...props} title={`${prop.rtlName}`} />
                )}
                key={key}
              />
            );
          })}
          <Redirect from="/web/main" to="/web/main/home" />
        </Switch>
      </div>
      <div
        className={`page-footer ${
          location.pathname === "/web/main/services"
            ? "white"
            : location.pathname === "/web/main/home"
            ? "grey"
            : ""
        }`}
      >
        <footer
          className="footer"
          style={{
            background: `url(${process.env.PUBLIC_URL}/img/footer.png)  no-repeat center center /cover`,
          }}
        >
          <div className="row">
            <div className="app ">
              <div className=" app__text">
                <h2>{t("footer.app_title")}</h2>
                <p>{t("footer.app_desc")}</p>
              </div>
              <div className="">
                <div className="app__btns">
                  <a className="btn btn--white no-hover has-long-radius img-only pd-0">
                    {/* <div className="btn__title">
                     <h6>احصل عليه من</h6>
                      <h3>Google Play</h3>
                     </div> */}
                    <img
                      src={`${process.env.PUBLIC_URL}/img/google_paly.svg`}
                      alt="download ecobon"
                    />
                  </a>
                  <a className="btn btn--white no-hover has-long-radius img-only  pd-0">
                    {/* <div className="btn__title">
                     <h6>احصل عليه من</h6>
                      <h3>Google Play</h3>
                     </div> */}
                    <img
                      src={`${process.env.PUBLIC_URL}/img/app_store.svg`}
                      alt="download ecobon"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="info">
              <div className="right">
                <h6>{t("footer.text")}</h6>
                <Link  to={{
                  pathname:"/web/main/rules",
                  state:{type:1}
                }} className="btn btn-text big">
                  {t("footer.policy_link")}
                </Link>
                <span>|</span>
                <Link to={{
                  pathname:"/web/main/rules",
                  state:{type:3}
                }} className="btn btn-text big">
                  {" "}
                  {t("footer.rules")}
                </Link>
              </div>
              <div className="left">
                <h6>{t("footer.followUs")} </h6>
                <ul>
                  <li>
                    <a href="#">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/facebook.svg`}
                        alt="download ecobon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/twitter.svg`}
                        alt="download ecobon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/whatsapp.svg`}
                        alt="download ecobon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/instgram.svg`}
                        alt="download ecobon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/linkedIn.svg`}
                        alt="download ecobon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <hr />

            <div className="info">
              <div className="right">
                <p>
                  جميع الحقوق محفوظة {new Date().getFullYear()} &copy; Copon
                  حقوق الطبع والنشر
                </p>
              </div>
              <div className="left">
                <p>
                  {t("footer.footer_designer")}:{" "}
                  <a href="" className="btn btn-text">
                    uxitar
                  </a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
});
