import { GetNotifications } from '../actions/generalActions'


export const returnError = (err) => {
  
  return err.response
  ? err.response.data?  [{ message: err.response.data.message }]:
   err.response.data.errors
    ? err.response.data.errors.length >0?err.response.data.errors:[{message:"خطأ غير معروف"}]
      : [{ message: err.message }]
    : [{ message: err.message }];
};

export const  makeid = (length) =>{
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * 
 charactersLength)));
   }
   return result.join('');
}
