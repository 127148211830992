import axios from "axios";
import i18Next from "i18next";
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, LOGIN_INIT } from "./types";

import { returnError } from "../helpers";
import {} from "./types";
import authService from "../services/auth-service";

import axiosService from "../services/axios-service";

const axiosInstance = axiosService.getInstance();


export const getStats = (data) => (dispatch) => {
  let LanguageID = i18Next.languages[0] === "en" ? 1 : 2;

  return axiosInstance
    .get(`/GetHomePageEcoponStatistics/${LanguageID}`)
    .then((res) => {
    
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};

export const GetCoponInformation =
  (EServiceID = 0, ECouponID = 0, pagesize = 0) =>
  (dispatch) => {
    let params = {
      LanguageID: i18Next.languages[0] === "en" ? 1 : 2,
      ECouponID: ECouponID,
      EServiceID: EServiceID,
      getCoponInfo: 1,
      getDraws: 1,
      getPrizes: 1,
      getWinners: 1,
      PageNumber: 1,
      pageSize: pagesize,
    };

    return axiosInstance
      .post(`/GetCoponInformation`, params)
      .then((res) => {
        return res.data.Data;
      })
      .catch((err) => {
        return Promise.reject(returnError(err));
      });
  };
export const GetVoucherListing =
  (pageSize = 15, CategoryID = 0) =>
  (dispatch) => {
    let params = {
      LanguageID: i18Next.languages[0] === "en" ? 1 : 2,
      PageNumber: 1,
      PageSize: pageSize,
      CategoryID: parseInt(CategoryID),
    };

    return axiosInstance
      .post(`/GetVoucherListing`, params)
      .then((res) => {
        return res.data.Data;
      })
      .catch((err) => {
        return Promise.reject(returnError(err));
      });
  };

export const GetSiteCompetitionNamesForDropDown = () => () => {
  return axiosInstance
    .get(
      `/GetSiteCompetitionNames/${i18Next.languages[0] === "en" ? "1" : "2"}`
    )
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};

export const GetWinnerDrawListForDropDown = (ecoponId) => () => {
  return axiosInstance
    .get(
      `/GetWinnerDrawList/${ecoponId}/${
        i18Next.languages[0] === "en" ? "1" : "2"
      }`
    )
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};
export const GetWinnerByFilters = (ecoponId, eCoponDrawId) => () => {
  return axiosInstance
    .get(
      `/GetWinnerByFilters/${ecoponId}/${eCoponDrawId}/${
        i18Next.languages[0] === "en" ? "1" : "2"
      }`
    )
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};
export const GetUserTerms = (ecoponId) => () => {
  return axiosInstance
    .get(
      `/GetUserTerms/${ecoponId}/${i18Next.languages[0] === "en" ? "1" : "2"}`
    )
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};


export const SaveECoupons = (data) => (dispatch) => {
  return axiosInstance
    .post(`/SaveECoupons`, data)
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};

///voucher

export const GetVoucherCategories = (data) => (dispatch) => {
  let params = { LanguageID: i18Next.languages[0] === "en" ? 1 : 2, ...data };

  return axiosInstance
    .post(`/GetVoucherCategories`, params)
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};
export const GetVoucherDetails = (data) => (dispatch) => {
  let params = { LanguageID: i18Next.languages[0] === "en" ? 1 : 2, ...data };

  return axiosInstance
    .post(`/GetVoucherDetails`, params)
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};

export const GetVoucherDirect = (data) => (dispatch) => {
  let params = { LanguageID: i18Next.languages[0] === "en" ? 1 : 2, ...data };

  return axiosInstance
    .post(`/GetVoucherDirect`, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};

//notifies
export const updateNotificationBadge=(num)=>(dispatch)=>{
   
  let userData =   authService.getToken();
    userData.NotificationCount = num
    authService.saveToken(userData)
   dispatch(
     {
      type: LOGIN_SUCCESS,
      payload: userData,
    }
   )
}
export const GetNotifications = (consumerId) => (dispatch) => {
  let LanguageID = i18Next.languages[0] === "en" ? 1 : 2;

  return axiosInstance
    .get(`/GetNotifications/${consumerId}/${LanguageID}`)
    .then((res) => {
      // update notifications badge every time we call this api;
      let notifies =  res.data.Data
       
    let userData =   authService.getToken();
      userData.NotificationCount = notifies.length
      authService.saveToken(userData)
     dispatch(
       {
        type: LOGIN_SUCCESS,
        payload: userData,
      }
     )
      return notifies;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};
export const ReadNotification = (id,consumerId) => (dispatch) => {
  let LanguageID = i18Next.languages[0] === "en" ? 1 : 2;
let params = {  
  " ConsumerNotificationID ":id,
  " LanguageID ":LanguageID,
  " ConsumerID ":consumerId
}

  return axiosInstance
    .post(`/ReadNotification`,{...params})
    .then((res) => {
      // update notifications badge every time we call this api;
     
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};


//
export const MyParticipations = (consumerId) => (dispatch) => {
  let LanguageID = i18Next.languages[0] === "en" ? 1 : 2;

  return axiosInstance
    .get(`/MyParticipations/${consumerId}/${LanguageID}`)
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};

export const GetPrivacyAndTerms = (typeId=3) => (dispatch) => {
  let LanguageID = i18Next.languages[0] === "en" ? 1 : 2;
 
  return axiosInstance
    .get(`/GetPrivacyAndTerms/${typeId}/${LanguageID}`)
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};

export const InsertContactUs = (data) => (dispatch) => {
  return axiosInstance
    .post(`/InsertContactUs`, data)
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};



// common actions

export const getVisitorsInfo = (id, type) => (dispatch) => {
  return axios
    .get(
      `https://api.ipgeolocation.io/ipgeo?apiKey=0ac6802b600a499c9e240d7b910e8831`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};
export const GetCountriesForRegistration = () => () => {
  
  return axiosInstance
    .get(`/GetCountriesForRegistration/${i18Next.languages[0] === "en" ? '1' : '2'}`)
    .then((res) => {
        
      return res.data.Data
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};
export const GetCountries = () => () => {
  return axiosInstance
    .get(`/GetCountries/${i18Next.languages[0] === "en" ? "1" : "2"}`)
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};

export const GetCities = (countryId) => () => {
  return axiosInstance
    .get(`/GetCities/${i18Next.languages[0] === "en" ? "1" : "2"}/${countryId}`)
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};

export const GetNationality = () => () => {
  return axiosInstance
    .get(`/GetNationality/${i18Next.languages[0] === "en" ? "1" : "2"}`)
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};

export const GetUserIdentityTypeList = () => () => {
  return axiosInstance
    .get(
      `/GetUserIdentityTypeList/${i18Next.languages[0] === "en" ? "1" : "2"}`
    )
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};