import React from "react";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();

  return (
    <div className="about">
      <div className="row">
        <div className="about__info"> 
          <h2>{t("about.header")}</h2>
          <p>{t("footer.text")}</p>
          <p>{t("about.body")}</p>
        </div>
        <div className="about__services">
          <h1>{t("about.header_sm")}</h1>
          <div className="about__services--boxes">
            <div className="about__services--boxes_box">
              <img
                src={`${process.env.PUBLIC_URL}/img/services1.svg`}
                alt="service about"
              />
              <h5>{t("about.title1")}</h5>
              <p>{t("about.body_sm")}</p>
            </div>

            <div className="about__services--boxes_box">
              <img
                src={`${process.env.PUBLIC_URL}/img/services2.svg`}
                alt="service about"
              />
              <h5>{t("about.title2")}</h5>
              <p>{t("about.body_sm")}</p>
            </div>
          </div>

          <div className="about__services--boxes">
            <div className="about__services--boxes_box">
              <img
                src={`${process.env.PUBLIC_URL}/img/services3.svg`}
                alt="service about"
              />
              <h5>{t("about.title3")}</h5>
              <p>{t("about.body_sm")}</p>
            </div>

            <div className="about__services--boxes_box">
              <img
                src={`${process.env.PUBLIC_URL}/img/services4.svg`}
                alt="service about"
              />
              <h5>{t("about.title5")}</h5>
              <p>{t("about.soon")}</p>
            </div>
          </div>
        </div>
        <div className="about__detail ">
          <h1 className="about__detail--title">{t("contact.تفاصيل أكثر")}</h1>
          <div className="about__detail--boxes">
            <div className="about__detail--box">
              <div className="icon">
                <img
                  src={`${process.env.PUBLIC_URL}/img/enail_con-main.svg`}
                  alt=""
                />
              </div>
              <div className="content">
                <h1>{t("contact.for_suggest")}</h1>
                <a href="" className="btn btn-text btn-text--black">
                  info@e-copon.com
                </a>
              </div>
            </div>

            <div className="about__detail--box">
              <div className="icon">
                <img
                  src={`${process.env.PUBLIC_URL}/img/helps-main.svg`}
                  alt=""
                />
              </div>
              <div className="content">
                <h1>{t("contact.for_support")}</h1>
                <a href="" className="btn btn-text btn-text--black">
                  support@e-copon.com
                </a>
              </div>
            </div>
            <div className="about__detail--box">
              <div className="icon">
                <img
                  src={`${process.env.PUBLIC_URL}/img/whatsapp_bussnis-main.svg`}
                  alt=""
                />
              </div>
              <div className="content">
                <h1>{t("contact.for_whatsapp")}</h1>
                <a href="" className="btn btn-text btn-text--black">
                  00556849541646498
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
