import React from "react";

export default function EmailInput({ disabled, icon, placeholder, error, changeEvent,success,value }) {
  const [email, setemail] = React.useState("");
  const [emailError, setemailError] = React.useState(false);
  const onEmailChange = (e) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const valid = re.test(String(e.target.value).toLowerCase());
    valid ? setemailError(false) : setemailError(true);
    changeEvent(e.target.value);
  };
  return (
    <>
      <input
        onChange={onEmailChange}
        value={value}
        disabled={disabled}
        type="email"
        className={`form__input ${icon ? "has-icon " : ""} circle ${
          emailError ? "error" : "" 
        }`}
        placeholder={placeholder || "البريدالالكتروني"}
      />
      {icon && (
        <img
          src={`${process.env.PUBLIC_URL}/img/email.svg`}
          alt="cobon user email"
          className="form__icon"
        />
      )}
    </>
  );
}
