import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import useWindowDimensions from "../helpers/JsMediaQuery";
import { useLocation, useParams,useHistory } from 'react-router-dom';

export default withRouter(function Breadcrumb({ routes, location }) {
  const [hasparent, setHasparent] = React.useState(false);
  const [reversed, setreversed] = React.useState([]);
  

  const { t } = useTranslation();
  let { coponId,serviceId } = useParams();

  // React.useEffect(() => {
  //   let counter = 0;
  //   let temp = [...routes];
  //   //reverse the order of routes to display in breadcrumb
  //   setreversed(temp.reverse());
  // console.log(history);
  //   routes.some((route) => {
  //     if (location.pathname.includes(route.layout + (route.shortendPath ||route.path))) {
  //       counter = counter + 1;
  //     }
     
  //     if (counter >= 2) {
  //       setHasparent(true);
  //       return true;
  //     } else {
  //       setHasparent(false);
  //       return false;
  //     }
  //   });
  // }, [location]);
  const crumb_slash = {
    margin: "auto .6rem",
  };

  const { height, width } = useWindowDimensions();
  return (
    <div className="row" style={{ marginBottom: "2rem" }}>
      {routes ? (
        <div className="goBack-btn right flex-wrap ">
          <img
            src={`${process.env.PUBLIC_URL}/img/${
              width > 1015 ? "chevron-left_2" : "chevron-left_white"
            }.svg`}
            alt="cobon back"
          />

          <Link
            to="/"
            className={`btn btn--normal ${
              width > 1015 ? "" : "btn--normal-white"
            } `}
          >
            {i18next.languages[0] === "en" ? "Home" : "الرئيسية"}
          </Link>
          {routes.map((route, i) =>
            <>
            <span key={i}>
              <span
                style={{
                  ...crumb_slash,
                  color: `${width > 1015 ? "#A3ACB8" : "#fff"}`,
                }}
                className="crumb-slash"
              >
                /
              </span>
              <Link
                className={`btn btn--normal ${
                  width > 1015
                    ? route.active
                      ? "btn--normal-blue"
                      : ""
                    : "btn--normal-white"
                } `}
                to={route.linkTo}
              >
                {i18next.languages[0] === "en" ? route.name : route.rtlName}
              </Link>
            </span>
          </>
          )}
        </div>
      ) : (
        <>
          <div className="goBack-btn right  hidden-mobile flex">
            <img
              src={`${process.env.PUBLIC_URL}/img/chevron-left_blue.svg`}
              alt="cobon back"
            />

            <Link to="/" className="btn btn--normal btn--normal-blue ">
              {t("auth.back")}
            </Link>
          </div>
          <div className="goBack-btn right  hidden-desktop">
            <img
              src={`${process.env.PUBLIC_URL}/img/chevron-left_white.svg`}
              alt="cobon back"
            />

            <Link to="/" className="btn btn--normal btn--normal-white ">
              {t("auth.back")}
            </Link>
          </div>
        </>
      )}
    </div>
  );
});
