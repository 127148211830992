import React from "react";

export default function NumberInput({disabled,normalNumber, icon, placeholder, error, changeEvent,success,value }) {
  const [text, setText] = React.useState("");

  const onchange = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      changeEvent(e.target.value);
    }
  };
  return (
    <>
      <input
        onChange={onchange}
        value={value}
        type="text"
        disabled={disabled}
        className={`form__input ${icon ? "has-icon" : ""} circle ${
          error ? "error" : success ? "success" : ""
        }`}
        placeholder={placeholder}
      />
      {icon && (
        <img
          src={`${process.env.PUBLIC_URL}/img/${normalNumber? 'id_card':'phone'}.svg`}
          alt="cobon back"
          className="form__icon"
        />
      )}

      <label className="form__label">رقم الهاتف</label>
    </>
  );
}
