import { useEffect } from "react";

import LoggedInRoute from "./helpers/LoggedInRoute";

import { checkAuth } from "./actions/authActions";

import { useSelector, useDispatch } from "react-redux";

import { getVisitorsInfo } from "./actions/generalActions";

import AuthLayout from "./Layouts/AuthLayout";

import EcoponLayout from "./Layouts/EcoponLayout";

import Phase4 from "./Layouts/Phase4";

import ScrollPos from "./helpers/ScrollPos";

import Phase3 from "./Layouts/Phase3";

import r from "./routes";

import Main from "./Layouts/Main";

import { Route, Switch, Redirect } from "react-router-dom";

function App() {
  // const switchRoutes = (
  //   <Switch>
  //     {r.routes.map((prop, key) => {
  //       return <Route path={prop.path} component={prop.component} key={key} />;
  //     })}
  //     <Redirect from="/" to="/home" />
  //   </Switch>
  // );
  const { errors, username, isLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkAuth());

    dispatch(getVisitorsInfo())
      .then((res) => {
        const data = { _id: res.ip, country: res.country_name, city: res.city };
        localStorage.setItem("visitor", JSON.stringify(res));
        localStorage.setItem("ip", res.ip);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <ScrollPos>
        <Switch>
          {/* <Route
            path="/phase5"
            render={(props) => <Phase5 {...props} routes={r.routes5} />}
          /> */}
          {/* <Route
            path="/phase4"
            render={(props) => <Phase4 {...props} routes={r.routes4} />}
          /> */}
          {/* <Route
            path="/phase3"
            render={(props) => <Phase3 {...props} routes={r.routes3} />}
          /> */}
          <Route
            path="/web/ecopon"
            render={(props) => (
              <EcoponLayout {...props} routes={r.ecoponroutes} />
            )}
          />
          <LoggedInRoute
            path="/web/auth"
            props={{ routes: r.authRoutes }}
            component={AuthLayout}
          />
          <Route
            path="/web/main"
            render={(props) => <Main {...props} routes={r.mainRoutes} />}
          />
          <Redirect from="/" to="/web/main" />
        </Switch>
      </ScrollPos>
    </div>
  );
}

export default App;
