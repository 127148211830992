import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import PasswordInput from "../../components/PasswordInput";
import { AppValidatePassword, loginSuccess } from "../../actions/authActions";
import Loading from "../../components/Loading";
import { LOGIN_REDIRECTION_URL } from "../../actions/types";

export default function PasswordScreen(props) {
  const [text, setText] = React.useState("");
  const [error, seterror] = React.useState("");
  const [loadingSubmit, setloadingSubmit] = React.useState(false);

  const [params, setparams] = React.useState({
    mobile: "0",
    countryId: "0",
    countryCode: "0",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const onBtnClick = () => {
    if (!disabled()) {
      setloadingSubmit(true);

      dispatch(AppValidatePassword(params, text))
        .then((e) => {
      
          setloadingSubmit(false);
          switch (e.Status) {
            case 0:
              seterror("UserInActiveOrNotVerified ");
              break;
            case 1:
              seterror("");

              dispatch(loginSuccess(e));
              let redir = sessionStorage.getItem(LOGIN_REDIRECTION_URL);
              setTimeout(() => {
                if (redir) {
                  sessionStorage.removeItem(LOGIN_REDIRECTION_URL);
                  history.replace(redir);
                } else {
                  history.replace("/");
                }
              }, 100);
              break;
            case 2:
              seterror("InvalidPassword");
              break;
            case 3:
              seterror("InvalidCredentials");
              break;
            case 4:
              seterror("UserDoesNotExists");
              break;
            case 5:
              seterror("InvalidMobile");
              break;
            case 6:
              seterror("InvalidCountrySelection");
              break;

            default:
              break;
          }
        })
        .catch((err) => {
          setloadingSubmit(false);
          seterror(err[0] ? err[0].message : "Error");
        });
    }
  };
  const onchange = (e) => {
    seterror("");
    setText(e.target.value);
  };
  const location = useLocation();
  React.useEffect(() => {
    if (props.location.state) {
      setparams(props.location.state.data);
    }
  }, []);
  const disabled = () => {
    return text.length < 1 || error;
  };
  const { t } = useTranslation();
  return (
    <div className="phone-form">
      <h1 className="auth__title">{t("auth.passord.title")}</h1>
      <h3 className="auth__subtitle">{t("auth.passord.sub")}</h3>

      <div className="auth__box">
        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="pos-rel">
              <PasswordInput
                onChange={onchange}
                error={error}
                placeholder={t("placeholders.passord")}
              />
            </div>
          </div>
        </div>
        <div className="form__footer">
          <div className="flex-between">
            <div className=" text-right   col-padding-small flex-center">
              <Link
                to={{
                  pathname: "/web/auth/revertpassword",
                  state: {
                    data: {
                      OtpID: params.OtpID,
                      MobileNumber: params.mobile,

                      ConsumerID: params.consumerId,
                      CountryID: params.countryId,
                    },
                  },
                }}
                className="btn btn-text hovered btn--small"
              >
                {t("auth.passord.dont-remember")}
              </Link>
            </div>
            <div className=" text-left  col-padding-small">
              {error && (
                <span className="error-text no-margin">
                  {/* {t("auth.passord.error")} */}
                  {error}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="form__group mg-top">
          <a
            onClick={onBtnClick}
            className={` btn bold custom-padding ${
              disabled() ? "btn--disabled" : "btn--green"
            } w-100`}
          >
            {loadingSubmit ? (
              <Loading smMargin={true} white={true} />
            ) : (
              t("placeholders.next")
            )}
          </a>
        </div>
      </div>
    </div>
  );
}
