import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import i18Next from "i18next";

import { GetVoucherCategories } from "../../actions/generalActions";
import Loading from "../../components/Loading";
import Breadcrumb from "../../components/Breadcrumb";

export default function Copons() {
  const [vouchers, setvouchers] = React.useState({
    loading: false,
    list: [],
  });
  const { t } = useTranslation();
  const { isAuth, userData } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  React.useEffect(() => {
    setvouchers({ ...vouchers, loading: true });
    let data = {
      CityID: 0,
      ConsumerID: isAuth ? userData.ConsumerID : 0,
      PageNumber: 1,
      PageSize: 100,
      SearchText: "",
    };

    // call vouchers
    dispatch(GetVoucherCategories(data))
      .then((e) => {
        setvouchers({ list: e.ResponseVoucherCategoryList, loading: false });
      })
      .catch((err) => {
        setvouchers({ list: [], loading: false });
      });
  }, [i18Next.languages]);

  const card = (c) => (
    <div
      className={`copons__card  ${c.VoucherTypeCount > 1 ? "has-alert" : ""}`}
    >
      <div className="copons__card--img">
        <img src={`${c.CategoryImageUrl}`} alt="card" />
      </div>
      <div className="copons__card--content">
        <h1>{c.CategoryName}</h1>
        {c.VoucherTypeCount > 1 && (
          <span className="flex-center">{c.VoucherTypeCount}</span>
        )}
      </div>
    </div>
  );
  return (
    <>
      <Breadcrumb />

      <div className="copons">
        <div className="row">
          <div className="copons__row ">
            {vouchers.loading ? (
              <Loading />
            ) : (
              vouchers.list.map((e, i) => (
                <Link
                  to={`/web/ecopon/vouchers/${e.CategoryID}/${e.CategoryName}`}
                  key={e.CategoryID}
                  className="copons__row--col "
                >
                  {card(e)}
                </Link>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}
