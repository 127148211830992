import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Breadcrumb from "../../components/Breadcrumb";
import i18Next from "i18next";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import {
  GetVoucherDetails,
  GetVoucherDirect,
} from "../../actions/generalActions";
import Loading from "../../components/Loading";
import { LOGIN_REDIRECTION_URL } from "../../actions/types";
import Modal from "../../components/Modal";
import { GetNotifications } from "../../actions/generalActions";


export default function CoponDetails() {
  const [voucher, setvoucher] = React.useState({
    loading: false,
    data: null,
    distributer: [],
  });
  const [redirect, setredirect] = React.useState(false);
  const [takeVoucherAction, settakeVoucherAction] = React.useState({
    loading: false,
    error: "",
    codeAfterSuccess: "",
  });
  const [successModelOpen, setsuccessModelOpen] = React.useState(false);
  const [failureModelOpen, setfailureModelOpen] = React.useState(false);
  const [userTermsModalOpen, setuserTermsModalOpen] = React.useState(false);
  const [userTerms, setuserTerms] = React.useState({
    loading: false,
    text: "",
  });

  const { isAuth, userData } = useSelector((state) => state.auth);

  let { categoryId, categoryName, voucherId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  //evnts

  const onUserTermsAgree = () => {
    if (!takeVoucherAction.loading) {
      settakeVoucherAction({ error: "", loading: true });
      let data = {
        ConsumerID: userData.ConsumerID,

        VoucherID: parseInt(voucherId),
      };

      // call voucher
      dispatch(GetVoucherDirect(data))
        .then((e) => {
          if (e.Data.ResponseGetVoucherDirect.Status === 1) {
            settakeVoucherAction({
              error: "",
              loading: false,
              codeAfterSuccess: e.Data.ResponseGetVoucherDirect.Barcode,
            });
            dispatch(GetNotifications(userData.ConsumerID))
            setsuccessModelOpen(true);
          } else {
            settakeVoucherAction({
              error: e.Data.ResponseGetVoucherDirect?.DisplayMessage,
              loading: false,
            });
            setfailureModelOpen(true);

          }
          setuserTermsModalOpen(false);
        })
        .catch((err) => {
          settakeVoucherAction({ error: err.message ||  t("forms.error"), loading: false });
          setuserTermsModalOpen(false);
          setfailureModelOpen(true);

        });
    }
  };
  const onUserTermsCancel = () => {
    setuserTermsModalOpen(false);
  };
  const onTakeVoucherClick = () => {
    if (isAuth) {
      setredirect(false);
      setuserTermsModalOpen(true);
    } else {
      setredirect(true);
      setTimeout(() => {
        sessionStorage.removeItem(LOGIN_REDIRECTION_URL);
        sessionStorage.setItem(LOGIN_REDIRECTION_URL, location.pathname);
        history.push("/web/auth/login-user");
      }, 2000);
    }
  };
  React.useEffect(() => {
    setvoucher({ ...voucher, loading: true });
    let data = {
      // CategoryID: parseInt(categoryId),
      ConsumerID: userData.ConsumerID || 0,

      VoucherID: parseInt(voucherId),
    };

    // call voucher
    dispatch(GetVoucherDetails(data))
      .then((e) => {
        setvoucher({
          data: e.ResponseVoucherDetails || null,
          distributer: e.DistributorList || [],
          loading: false,
        });

      })
      .catch((err) => {
        setvoucher({ data: null, loading: false, distributer: [] });
      });
  }, [i18Next.languages]);

  return (
    <>
      <Breadcrumb
        routes={[
          {
            name: "Vouchers Categories",
            rtlName: "اصناف القسائم",
            linkTo: "/web/ecopon/vouchers",
          },
          {
            name: `${categoryName || "vouchers"}`,
            rtlName: `${categoryName || "القسائم"}`,
            // linkTo: `/web/ecopon/vouchers/${categoryId}`,
            linkTo: `/web/ecopon/vouchers`,
          },
          {
            name: `${voucher.data?.voucherName || "Voucher Details"}`,
            rtlName: `${voucher.data?.voucherName || "تفاصيل القسيمة"}`,
            linkTo: `/web/ecopon/voucherdetails/${voucherId}`,
            active: true,
          },
        ]}
      />
      {voucher.loading ? (
        <Loading />
      ) : voucher.data ? (
        <div className="bottom-distance">
          <Modal
            closeModal={() => setfailureModelOpen(false)}
            openModal={() => setfailureModelOpen(true)}
            modalIsOpen={failureModelOpen}
            title={t("phase4.reject")}
          >
            <div className="new-account-form message">
              <div className="message-box">
                <img
                  src={`${process.env.PUBLIC_URL}/img/error.svg`}
                  alt="message"
                />
                <p>{takeVoucherAction.error}</p>
                <a
                  onClick={() => setfailureModelOpen(false)}
                  className="btn btn--green w-100"
                >
                  {t("phase4.btn_agree")}
                </a>
              </div>
            </div>
          </Modal>

          <Modal
            closeModal={() => setsuccessModelOpen(false)}
            openModal={() => setsuccessModelOpen(true)}
            modalIsOpen={successModelOpen}
            title={t("phase4.comp.success")}
          >
            <div className="new-account-form message">
              <div className="message-box">
                <img
                  src={`${process.env.PUBLIC_URL}/img/check.svg`}
                  alt="message"
                />
                <p className="codeAfterSuccess">{takeVoucherAction.codeAfterSuccess}</p>
                <a
                  onClick={() => setsuccessModelOpen(false)}
                  className="btn btn--green w-100"
                >
                  Close
                </a>
              </div>
            </div>
          </Modal>
          <Modal
            closeModal={() => setuserTermsModalOpen(false)}
            openModal={() => setuserTermsModalOpen(true)}
            modalIsOpen={userTermsModalOpen}
            title={t("footer.rules")}
          >
            <div className="new-account-form ">
              <div className="policy">
                <div className="policy__content">
                  <p
                    dangerouslySetInnerHTML={{ __html: voucher.data.Terms }}
                  ></p>
                </div>
                <div className="policy__btns">
                  <a
                    onClick={onUserTermsAgree}
                    className="btn btn--yellow first"
                  >
                        {takeVoucherAction.loading ? (
                        <Loading smMargin white={true} />
                      ) : (
                        t("phase4.btn_agree")
                      )}{" "}
                  
                  </a>
                  <a
                    onClick={onUserTermsCancel}
                    className="btn btn--disabled reject"
                  >
                    {t("phase4.cancel")}
                  </a>
                </div>
              </div>
            </div>
          </Modal>

          <div className="row">
            <div className="comp-details big">
              <div className="comp-details__body">
                <div className="comp-details__body--data">
                  <div className="comp-details__body--img">
                    <img src={`${voucher.data.PrimaryImageURL}`} alt="" />
                  </div>
                  <div className="comp-details__body--text">
                    <h1>{voucher.data.VoucherName}</h1>
                    {/* <p>{t("phase5.comp.copon")}: </p>
                    <div className="competitions__card--content details">
                      <h4 className="price">
                        <span className="main">
                          {voucher.data.PriceAfterDiscount}{" "}
                          <span>{voucher.data.Currency} </span>
                        </span>
                        <span className="text">
                          {t("phase5.card.instead")}{" "}
                        </span>
                        <span className="red">
                          {voucher.data.OriginalPrice}{" "}
                          <span>{voucher.data.Currency}</span>
                        </span>
                      </h4>
                    </div> */}
                    <p className="mg-top-big">{t("phase5.comp.address")}: </p>
                    <p>
                      <strong className="font-big">
                        {voucher.data.LinkLabel}:{" "}
                      </strong>
                    </p>
                    <p className="mg-top-big">
                      {t("phase5.comp.destribute")}:{" "}
                    </p>
                    <p>
                      {voucher.distributer.map((e, i) => (
                        <strong key={i} className="font-big">
                          {e.CorporateName},{" "}
                        </strong>
                      ))}
                    </p>
                    <p className="mg-top-big">{t("phase3.notify.desc_title")}: </p>
                    <p >
                        <strong  className="force-font font-big force-font-size"  dangerouslySetInnerHTML={{ __html: voucher.data.Description }}>

                        </strong>
                    </p>
                    <a
                      onClick={onTakeVoucherClick}
                      className={` btn middle  btn--green mg-top-bigger display-in-bl         
             `}
                    >
                      {
                        t("phase5.comp.btn")
                      }
                    </a>
                    {redirect && (
                      <h6 className="error-text mg-top">
                        {t("phase3.redirecting")}
                      </h6>
                    )}
                    {/* {takeVoucherAction.error && (
                      <h6 className="error-text mg-top">
                        {takeVoucherAction.error}
                      </h6>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <p>{t("phase3.error")}</p>
        </div>
      )}
    </>
  );
}
