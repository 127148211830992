import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import i18Next from "i18next";

import Select from "../../components/Select";
import EmailInput from "../../components/EmailInput";
import PasswordInput from "../../components/PasswordInput";
import {
  GetCountries,
  GetUserIdentityTypeList,
  GetCities,
  GetNationality,
} from "../../actions/generalActions";
import { MobileWebConsumerRegister } from "../../actions/authActions";
import NumberInput from "../../components/NumberInput";
import Loading from "../../components/Loading";

export default function Rigester(props) {
  const [password, setpassword] = React.useState("");
  const [confirmPassword, setconfirmPassword] = React.useState("");
  const [registerSubmitInfo, setregisterSubmitInfo] = React.useState({
    loading: false,
    error: "",
  });
  const [formData, setformData] = React.useState({
    CityID: 0,
    CountryID: "",
    Email: "",
    FullName: "",
    Gender: "",
    Password: "",
    Mobile: "",
    UseExistingDetails: 0,
    NationalityID: 0,
    UserIdentityNumber: "",
    UserIdentityTypeID: 0,
  });
  const [countries, setcountries] = React.useState({
    loading: false,
    list: [],
  });
  const [cities, setcities] = React.useState({
    loading: false,
    list: [],
  });
  const [nationalities, setnationalities] = React.useState({
    loading: false,
    list: [],
  });
  const [nationalitiesTypes, setnationalitiesTypes] = React.useState({
    loading: false,
    list: [],
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const options = [
    { value: "1", label: "فرغ" },
    { value: "2", label: "السحب الثاني" },
    { value: "3", label: "السحب الثالث" },
    { value: "4", label: "السحب الرابع" },

    { value: "5", label: "السحب الخامس" },
  ];
  const genders = [
    { value: "1", label: `${t("forms.male")}` },
    { value: "2", label: `${t("forms.female")}` },
  ];

  const findInArr = (arr = [], value, property) => {
    let ind = arr.findIndex((e) => e[property] === value);
    return ind !== -1 ? arr[ind] : [];
  };

  const callDropdownLists = () => {
    //call countries
    setcountries({ ...countries, loading: true });

    setcities({ ...cities, list: [] });

    dispatch(GetCountries())
      .then((e) => {
        setcountries({ list: e, loading: false });
      })
      .catch((err) => {
        setcountries({ list: [], loading: false });
      });

    // call nationalities
    setnationalities({ ...nationalities, loading: true });
    dispatch(GetNationality())
      .then((e) => {
        setnationalities({ list: e, loading: false });
      })
      .catch((err) => {
        setnationalities({ list: [], loading: false });
      });

    // call nationalitiesTypes
    setnationalitiesTypes({ ...nationalitiesTypes, loading: true });
    dispatch(GetUserIdentityTypeList())
      .then((e) => {
        setnationalitiesTypes({ list: e, loading: false });
      })
      .catch((err) => {
        setnationalitiesTypes({ list: [], loading: false });
      });
  };
  const disabled = () =>
    registerSubmitInfo.loading || password !== confirmPassword;
  //events

  const onRegisterSubmit = (e) => {
    if (!disabled()) {
      setregisterSubmitInfo({ ...registerSubmitInfo, loading: true });
      dispatch(MobileWebConsumerRegister({ ...formData, Password: password }))
        .then((res) => {
          setregisterSubmitInfo({ error: "", loading: false });

          if (res.Status === 1) {
            setregisterSubmitInfo({ error: "", loading: false });
            props.history.push({
              pathname: "/web/auth/login-user",
            });
          } else {
            setregisterSubmitInfo({ error: res.Message, loading: false });
          }
        })
        .catch((err) => {
          setregisterSubmitInfo({ error: t("forms.error"), loading: false });
        });
    }
  };
  const onCountryChange = (e) => {
    setcities({ ...cities, loading: true });
    updateFormData(e.CountryID, "CountryID");

    // call first drop down
    dispatch(GetCities(e.CountryID))
      .then((res) => {
        setcities({ list: res, loading: false });
      })
      .catch((err) => {
        setcities({ list: [], loading: false });
      });
  };
  const updateFormData = (value, property) => {
    let nwData = { ...formData };
    nwData[property] = value;
    setformData(nwData);
  };
  // cycles
  React.useEffect(() => {
    if (location.state) {
      if (location.state.third) {
        let data = location.state.data;
        let nwData = { ...formData };
        nwData["FullName"] = data.FullName;
        nwData["Email"] = data.EmailID;
        nwData["Mobile"] = data.MobileNumber;

        nwData["UseExistingDetails"] = 1;

        setformData(nwData);
      } else updateFormData(location.state.mobile, "Mobile");
    }
  }, []);
  React.useEffect(() => {
    callDropdownLists();
  }, [i18Next.languages]);
  return (
    <div className="phone-form">
      <h1 className="auth__title">{t("auth.register.title")}</h1>
      <h3 className="auth__subtitle">{t("auth.register.sub")}</h3>

      <div className="auth__box">
        <form action="">
          <div className="form__group big-btm">
            <div className="row row--no-padding">
              <div className="col-1-of-2 col-padding-small pos-rel">
                <input
                  value={formData.FullName}
                  onChange={(e) => updateFormData(e.target.value, "FullName")}
                  type="text"
                  className={`form__input has-icon  circle `}
                  placeholder={t("placeholders.full-name")}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/img/user_2.svg`}
                  alt="cobon user name"
                  className="form__icon"
                />
              </div>
              <div className="col-1-of-2 col-padding-small pos-rel">
                <EmailInput
                  placeholder={t("placeholders.email")}
                  changeEvent={(e) => {
                    updateFormData(e, "Email");
                  }}
                  value={formData.Email}
                  icon={true}
                />
              </div>
            </div>
          </div>
          <div className="form__footer">
            <div className="d-center">
              <div className=" text-right   col-padding-small flex-center">
                <p className="">{t("auth.register.form-header")}</p>
              </div>
            </div>
          </div>
          <div className="form__group mg-top">
            <div className="row row--no-padding">
              <div className="col-1-of-2 col-padding-small pos-rel">
                <Select
                  className="filter-select in-form "
                  classNamePrefix="select"
                  placeholder={t("placeholders.card_type")}
                  name="nationalityType"
                  onChange={(e) => {
                    updateFormData(e.UserIdentityTypeID, "UserIdentityTypeID");
                  }}
                  isSearchable={true}
                  isLoading={nationalitiesTypes.loading}
                  options={nationalitiesTypes.list}
                  getOptionLabel={(option) => option.UserIdentityName}
                  getOptionValue={(option) => option.UserIdentityTypeID}
                  value={findInArr(
                    nationalitiesTypes.list,
                    formData?.UserIdentityTypeID,
                    "UserIdentityTypeID"
                  )}
                />
              </div>
              <div className="col-1-of-2 col-padding-small pos-rel">
                <NumberInput
                  placeholder={t("placeholders.id_card")}
                  changeEvent={(e) => {
                    updateFormData(e, "UserIdentityNumber");
                  }}
                  icon
                  value={formData.UserIdentityNumber}
                />
              </div>
            </div>
          </div>

          <div className="form__group ">
            <div className="row row--no-padding">
              <div className="col-1-of-2 col-padding-small pos-rel">
                <Select
                  className="filter-select in-form  has-icon"
                  classNamePrefix="select"
                  value={findInArr(genders, formData.Gender, "value")}
                  onChange={(e) => {
                    updateFormData(e.value, "Gender");
                  }}
                  placeholder={t("placeholders.sex")}
                  name="gender"
                  options={genders}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/img/gender.svg`}
                  alt="cobon back"
                  className="form__icon"
                />
              </div>
              <div className="col-1-of-2 col-padding-small pos-rel">
                <Select
                  className="filter-select in-form "
                  classNamePrefix="select"
                  placeholder={t("placeholders.nationality")}
                  name="nationality"
                  onChange={(e) => {
                    updateFormData(e.NationalityID, "NationalityID");
                  }}
                  isSearchable={true}
                  isLoading={nationalities.loading}
                  options={nationalities.list}
                  getOptionLabel={(option) => option.NationalityName}
                  getOptionValue={(option) => option.NationalityID}
                  value={findInArr(
                    nationalities.list,
                    formData.NationalityID,
                    "NationalityID"
                  )}
                />
              </div>
            </div>
          </div>

          <div className="form__group ">
            <div className="row row--no-padding">
              <div className="col-1-of-2 col-padding-small pos-rel">
                <Select
                  className="filter-select in-form"
                  classNamePrefix="select"
                  placeholder={t("placeholders.country")}
                  name="country"
                  onChange={onCountryChange}
                  isSearchable={true}
                  isLoading={countries.loading}
                  options={countries.list}
                  getOptionLabel={(option) => option.CountryName}
                  getOptionValue={(option) => option.CountryID}
                  value={findInArr(
                    countries.list,
                    formData.CountryID,
                    "CountryID"
                  )}
                />
              </div>
              <div className="col-1-of-2 col-padding-small pos-rel">
                <Select
                  className="filter-select in-form"
                  classNamePrefix="select"
                  placeholder={t("placeholders.city")}
                 
                  onChange={(e) => {
                    updateFormData(e.CityID, "CityID");
                  }}
                  isSearchable={true}
                  isLoading={cities.loading}
                  options={cities.list}
                  getOptionLabel={(option) => option.CityName}
                  getOptionValue={(option) => option.CityID}
                  value={findInArr(cities.list, formData.CityID, "CityID")}
                />
              </div>
            </div>
          </div>

          <div className="form__group ">
            <div className="row row--no-padding">
              <div className="col-1-of-2 col-padding-small pos-rel">
                <PasswordInput
                  onChange={(e) => setpassword(e.target.value)}
                  value={password}
                  placeholder={t("placeholders.passord")}
                />
              </div>
              <div className="col-1-of-2 col-padding-small pos-rel">
                <PasswordInput
                  onChange={(e) => setconfirmPassword(e.target.value)}
                  error={confirmPassword !== "" && password !== confirmPassword}
                  placeholder={t("placeholders.confirm")}
                />
              </div>
            </div>
          </div>

          <div className="form__group mg-top-big">
            {registerSubmitInfo.error && (
              <div className="mg-btm">
                <span className="error-text">{registerSubmitInfo.error}</span>
              </div>
            )}
            <a
              onClick={onRegisterSubmit}
              className={` btn bold custom-padding ${
                disabled() ? "btn--disabled" : "btn--green"
              } w-100`}
            >
              {registerSubmitInfo.loading ? (
                <Loading white smMargin />
              ) : (
                t("placeholders.next")
              )}{" "}
            </a>
          </div>
        </form>
      </div>
    </div>
  );
}
