import React from "react";
import { useTranslation } from "react-i18next";
import i18Next from "i18next";
import { useDispatch } from "react-redux";

import EmailInput from "./EmailInput";
import Loading from "./Loading";
import { InsertContactUs } from "../actions/generalActions";
import Modal from "./Modal";






/**
 * 
 * ! Labels are included in some inputs but not shown in UI, feel free to use it if you feel to
 */
export default function Form() {
  const { t } = useTranslation();

  const [text, setText] = React.useState("");
  const [num, setnum] = React.useState("");
  const [formData, setformData] = React.useState({
    "ContactTypeId":1,
    "Subject":"",
    "Name":"",
    "Email":"",
    "ConfirmEmail":"",
    "City":"",
    "ContactNumber":"",
    "Question":"",
    "LanguageID":i18Next.languages[0] === "en" ? 1 : 2

  });
  const [submitForm, setsubmitForm] = React.useState({
    loading: false,
    error: "",
  });
  const [SuccessModalIsOpen, setSuccessModal] = React.useState(false);
  const [FailModalIsOpen, setFailModal] = React.useState(false);

  const dispatch = useDispatch()
  function openModalSuccess() {
    setSuccessModal(true);
  }

  function closeModalSuccess() {
    setSuccessModal(false);
  }
  function openModalFail() {
    setFailModal(true);
  }

  function closeModalFail() {
    setFailModal(false);
  }
  /**
   * 
   * * a function that allows only number values to be set to the Number input
   */
  
  const onchangeNum = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setnum(e.target.value);
    }
  };
  const sendQuery = (e) => {
    e.preventDefault()
    if(!disabled()){
      setsubmitForm({ ...submitForm, loading: true });
 
      dispatch(InsertContactUs({...formData,ConfirmEmail: formData.Email}))
        .then((res) => {

     
          if( res.IsValid){
          setsubmitForm({ error: "", loading: false });

          openModalSuccess()
          }else{
            setsubmitForm({ error: res.Message, loading: false });

            openModalFail()

          }
        
        })
        .catch((err) => {
          setsubmitForm({ error: t("forms.error"), loading: false });
        });

    }
  };


  const disabled = ()=> submitForm.loading || (!formData.Question && !formData.Subject && (!formData.ContactNumber || !formData.Email) && !formData.Name)
  const updateFormData = (value, property) => {
    let nwData = { ...formData };
    nwData[property] = value;
    setformData(nwData);
  };
  return (
    <>
     <Modal
        closeModal={closeModalSuccess}
        openModal={openModalSuccess}
        modalIsOpen={SuccessModalIsOpen}
        title={t("phase4.comp.success")}
      >
        <div className="new-account-form message">
          <div className="message-box">
            <img
              src={`${process.env.PUBLIC_URL}/img/check.svg`}
              alt="message"
            />
            <p>{t("contact.sent")}</p>
            <a onClick={closeModalSuccess} className="btn btn--green w-100">
              {t("phase4.btn_agree")}
            </a>
          </div>
        </div>
      </Modal>
      <Modal
        closeModal={closeModalFail}
        openModal={openModalFail}
        modalIsOpen={FailModalIsOpen}
        title={t("phase4.comp.fail")}
      >
        <div className="new-account-form message">
          <div className="message-box">
            <img
              src={`${process.env.PUBLIC_URL}/img/error.svg`}
              alt="message"
            />
            <p>{submitForm.error}</p>
            <a onClick={closeModalFail} className="btn btn--green w-100">
              {t("phase4.btn_agree")}
            </a>
          </div>
        </div>
      </Modal>
    
    <form onSubmit={sendQuery} className="form font-0">
      <div className="form__group md-btm ">
        <div className=" d-center">
          <div className="form__radio-group ">
            <input
              type="radio"
              value='1'
              checked ={formData.ContactTypeId ===1}
              onChange={(e) => updateFormData(parseInt(e.target.value), "ContactTypeId")}
              className="form__radio-input"
              id="support"
              name="type"
            />
            <label htmlFor="support" className="form__radio-label">
              <span className="form__radio-button"></span>
              {t("placeholders.support")}
            </label>
          </div>
          <div className="form__radio-group ">
            <input
              type="radio"
              value='2'
              checked ={formData.ContactTypeId ===2}
              onChange={(e) => updateFormData(parseInt(e.target.value), "ContactTypeId")}
              className="form__radio-input"
              id="service"
              name="type"
            />
            <label htmlFor="service" className="form__radio-label">
              <span className="form__radio-button"></span>
              {t("placeholders.service")}
            </label>
          </div>
          <div className="form__radio-group ">
            <input
              type="radio"
              className="form__radio-input"
              value='3'
              checked ={formData.ContactTypeId ===3}
              onChange={(e) => updateFormData(parseInt(e.target.value), "ContactTypeId")}
              id="info"
              name="type"
            />
            <label htmlFor="info" className="form__radio-label">
              <span className="form__radio-button"></span>
              {t("placeholders.info")}
            </label>
          </div>
        </div>
      </div>
      <h1 className="contact__detail--title">{t("contact.stay_in_contact")}</h1>

      <div className="form__group md-btm">
        <input
          value={formData.Subject}
          onChange={(e) => updateFormData(e.target.value, "Subject")}
          type="text"
          className="form__input"
          placeholder={t("placeholders.address")}
          required
          id="name"
        />
        <label htmlFor="name" className="form__label">
          {t("placeholders.address")}
        </label>
      </div>
      <div className="form__group md-btm">
        <div className="row row--no-padding">
          <div className="col-1-of-2  col-padding-small">
            <input
              type="text"
              value={formData.Name}
              onChange={(e) => updateFormData(e.target.value, "Name")}
              className="form__input"
              placeholder={t("placeholders.your-name")}
              required
              id="name"
            />
            <label htmlFor="name" className="form__label">
              {t("placeholders.your-name")}
            </label>
          </div>
          <div className="col-1-of-2 col-padding-small">
            <input
              type="email"
              className="form__input"
              placeholder={t("placeholders.email")}
              required
              value={formData.Email}
              onChange={(e) => {
         
                updateFormData(e.target.value, "Email")
                // updateFormData(e.target.value, "ConfirmEmail")
              }}
              id="emailForm"
            />
            <label htmlFor="emailForm" className="form__label">
              {t("placeholders.email")}
            </label>
          </div>
        </div>
      </div>
      <div className="form__group md-btm">
        <div className="row row--no-padding">
          <div className="col-1-of-2  col-padding-small">
            <input
              type="text"
              className="form__input"
              placeholder={t("placeholders.city")}
              value={formData.City}
              onChange={(e) => updateFormData(e.target.value, "City")}
              required
              id="city"
            />
            <label htmlFor="name" className="form__label">
              {t("placeholders.city")}{" "}
            </label>
          </div>
          <div className="col-1-of-2 col-padding-small">
            <input
              onChange={onchangeNum}
              value={num}
              type="text"
              className="form__input"
              value={formData.ContactNumber}
              onChange={(e) => updateFormData(e.target.value, "ContactNumber")}
              placeholder={t("placeholders.phone")}
              required
              id="phone"
            />
            <label htmlFor="phone" className="form__label">
              {t("placeholders.phone")}{" "}
            </label>
          </div>
        </div>
      </div>

      <div className="form__group md-btm2 display-in-bl">
        <textarea
          name="messageContent"
          id="message"
          value={formData.Question}
          onChange={(e) => updateFormData(e.target.value, "Question")}
          className="form__input form__input--textarea"
          placeholder={t("placeholders.message")}
          required
        ></textarea>
        <label htmlFor="message" className="form__label">
          {t("placeholders.message")}{" "}
        </label>
      </div>
      <div className="form__group mg-top">
        <button
        onClick={sendQuery}
          className={`btn ${
            disabled() ? "btn--disabled" : "btn--green"
          } w-100`}
        >
          {submitForm.loading ? <Loading white smMargin /> : t("placeholders.send")}{" "}
        </button>
      </div>
    </form>
 </> 
  );
}
