import Home from "./Views/Main/Home/HomeIndex";

import SuccessWithQr from "./Views/phase4/SuccessWithQR";

import Rules from "./Views/Main/Rules";

import About from "./Views/Main/About";

import Random from "./Views/phase4/Random";

import PersonalProfile from "./Views/phase4/PersonalProfile";

import LoginWithCodeForm from "./Views/phase4/LoginWithCodeForm";

import LoginWithPassword from "./Views/phase4/LoginWithPassword";

import LoginUser from "./Views/phase4/LoginUser";

import Success from "./Views/phase4/Success";

import RaceTitle from "./Views/phase4/RaceTitle";

import Reject from "./Views/phase4/Reject";

import CodeCheck from "./Views/phase4/CodeCheck";

import PolicyPage from "./Views/phase4/PolicyPage";

import UploadFiles from "./Views/phase4/UploadFiles";

import LoginAccountForm from "./Views/phase4/LoginAccountForm";

import NewAccountForm from "./Views/phase4/NewAccountForm";

import Competition from "./Views/phase4/Competition";

import RigesterCompany from "./Views/authViews/RigesterCompany";

import LoginCompany from "./Views/authViews/LoginCompany";

import NotifiesPrev from "./Views/Phase3/Notifies";

import Participations2 from "./Views/Phase3/Participations";

import Password from "./Views/Phase3/Password";

import MyData from "./Views/Main/MyProfile";

import RecreatePasword from "./Views/authViews/RecreatePasword";

import RevertPasswordForm from "./Views/authViews/RevertPasswordForm";

import Rigester from "./Views/authViews/RigesterScreen";

import FinishForm from "./Views/authViews/FinishForm";

import PasswordForm from "./Views/authViews/EnterPasswordScreen";

import CodeForm from "./Views/authViews/CodeFormScreen";

import AuthPage from "./Views/authViews/PhoneScreen";

import Contact from "./Views/Main/Contact";
import Services from "./Views/Main/Services";
import Winners from "./Views/Main/Winners";
import Competitions from "./Views/ecoponViews/Competitions";
import CompetitionDetails from "./Views/ecoponViews/CompetitionDetails/Index";
import Result from "./Views/phase5/Result";
import Copons from "./Views/phase5/Copons";
import CoponDetails from "./Views/phase5/CoponDetails";
import Resturants from "./Views/phase5/Resturants";
import Logout from "./Views/authViews/Logout";
import VoucherCategories from "./Views/ecoponViews/VoucherCategories";
import VouchrsList from "./Views/ecoponViews/VouchrsList";
import VoucherDetails from "./Views/ecoponViews/VoucherDetails";
import Notifies from "./Views/Main/Notifies";
import Participations from "./Views/Main/Participations";
import ViewResults from "./Views/ecoponViews/CompetitionDetails/ViewResults";








var mainRoutes = [
  {
    path: "/home",
    name: "Home",
    rtlName: "الرئيسية",

    component: Home,
    layout: "/web/main",
  },
  
  {
    path: "/about",
    name: "About Us",
    rtlName: "من نحن",

    component: About,
    layout: "/web/main",
  },
  {
    path: "/services",
    name: "Serivces",
    rtlName: "خدماتنا",
    layout: "/web/main",

    component: Services,
  },
  {
    path: "/winners",
    name: "Winners",
    rtlName: "قائمة الفائزين",
    layout: "/web/main",

    component: Winners,
  },
  {
    path: "/contact",
    name: "Contact Us",
    rtlName: "تواصل بنا",
    layout: "/web/main",

    component: Contact,
  },
  {
    path: "/rules",
    name: "Terms",
    rtlName: "الشروط ",
    layout: "/web/main",
    titles:{
      terms:{
        name: "Terms",
        rtlName: "الشروط والأحكام",
      },
      policy:{
        name: "Privacy Policy",
        rtlName: "سياسة الخصوصية ",
      }
    },
    component: Rules,
  },
  
  {
    path: "/logout",
    name: "Logout Page",
    rtlName: "",
    layout: "/web/main",

    component: Logout,
  },
  {
    path: "/my-profile",
    name: "My Data",
    rtlName: "بياناتي",
    protected:true,
    component: MyData,
    layout: "/web/main",
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "الاشعارات",

    component: Notifies,
    layout: "/web/main",
  },
  {
    path: "/participations",
    name: "Participations",
    rtlName: "مشاركاتي",

    component: Participations,
    layout: "/web/main",
  },
];
var ecoponroutes=[
  {
    path: "/voucherdetails/:voucherId",
    name: "Voucher Details",
    rtlName: "تفاصيل القسيمة",

    component: VoucherDetails,
    layout: "/web/ecopon",
  },
  {
    path: "/vouchers/:categoryId",
    name: "Vouchers",
    rtlName: "الكوبونات",
    component: VouchrsList,
    layout: "/web/ecopon"

  },
  {
    path: "/vouchers",
    name: "Vouchers ",
    rtlName: " الكوبونات ",
    
    component: VoucherCategories,
    layout: "/web/ecopon"

  },
  {
    path: "/competitions/:coponId/:serviceId",
    name: "Comp Details",
    rtlName: "تفاصيل المسابقة",
   
    shortendPath:"/competitions/single",
    component: CompetitionDetails,
    layout: "/web/ecopon",
  },
  {
    path: "/competitions-results",
    name: "Results",
    rtlName: "نتائج المسابقة",
    
    component: ViewResults,
    layout: "/web/ecopon",

  },
  {
    path: "/competitions",
    name: "Competitions",
    rtlName: "المسابقات",
    
    component: Competitions,
    layout: "/web/ecopon",

  },
]
var authRoutes = [
 
  {
    path: "/login-user",
    name: "Auth Page",
    rtlName: "بيانات الدخول",
    layout: "/web/auth",

    component: AuthPage,
  },
  {
    path: "/code",
    name: "Code Form",
    rtlName: "فورم الكود",
    layout: "/web/auth",

    component: CodeForm,
  },
  {
    path: "/password",
    name: "Code Form",
    rtlName: "فورم الكود",
    layout: "/web/auth",

    component: PasswordForm,
  },
  {
    path: "/existing-data",
    name: "finish Form",
    rtlName: "فورم الانهاء",
    layout: "/web/auth",

    component: FinishForm,
  },
  {
    path: "/register",
    name: "register Form",
    rtlName: "فورم التسجيل",
    layout: "/web/auth",

    component: Rigester,
  },
  {
    path: "/revertpassword",
    name: "revertpassword Form",
    rtlName: "فورم ",
    layout: "/web/auth",

    component: RevertPasswordForm,
  },
  {
    path: "/loginCompany",
    name: "loginCompany",
    rtlName: "loginCompany ",
    layout: "/web/auth",

    component: LoginCompany,
  },
  {
    path: "/registerCompany",
    name: "registerCompany",
    rtlName: "registerCompany ",
    layout: "/web/auth",

    component: RigesterCompany,
  },
  {
    path: "/recreate",
    name: "recreate Form",
    rtlName: "فورم ",
    layout: "/web/auth",

    component: RecreatePasword,
  },
];

////



// I left the prev routes commented in case you want to review the rest of the views


/**
 var routes1 = [
  {
    path: "/home",
    name: "Home",
    rtlName: "الرئيسية",

    component: Home,
    layout: "/phase1",
  },
  {
    path: "/about",
    name: "About Us",
    rtlName: "من نحن",

    component: About,
    layout: "/phase1",
  },
  {
    path: "/services",
    name: "Serivces",
    rtlName: "خدماتنا",
    layout: "/phase1",

    component: Services,
  },
  {
    path: "/winners",
    name: "Winners",
    rtlName: "قائمة الفائزين",
    layout: "/phase1",

    component: Winners,
  },
  {
    path: "/contact",
    name: "Contact Us",
    rtlName: "تواصل بنا",
    layout: "/phase1",

    component: Contact,
  },
  {
    path: "/rules",
    name: "Terms",
    rtlName: "الشروط ",
    layout: "/phase1",

    component: Rules,
  },
];
var routes2 = [
  {
    path: "/auth",
    name: "Auth Page",
    rtlName: "بيانات الدخول",
    layout: "/phase2",

    component: AuthPage,
  },
  {
    path: "/code",
    name: "Code Form",
    rtlName: "فورم الكود",
    layout: "/phase2",

    component: CodeForm,
  },
  {
    path: "/password",
    name: "Code Form",
    rtlName: "فورم الكود",
    layout: "/phase2",

    component: PasswordForm,
  },
  {
    path: "/finish",
    name: "finish Form",
    rtlName: "فورم الانهاء",
    layout: "/phase2",

    component: FinishForm,
  },
  {
    path: "/register",
    name: "register Form",
    rtlName: "فورم التسجيل",
    layout: "/phase2",

    component: Rigester,
  },
  {
    path: "/revertpassword",
    name: "revertpassword Form",
    rtlName: "فورم ",
    layout: "/phase2",

    component: RevertPasswordForm,
  },
  {
    path: "/loginCompany",
    name: "loginCompany",
    rtlName: "loginCompany ",
    layout: "/phase2",

    component: LoginCompany,
  },
  {
    path: "/registerCompany",
    name: "registerCompany",
    rtlName: "registerCompany ",
    layout: "/phase2",

    component: RigesterCompany,
  },
  {
    path: "/recreate",
    name: "recreate Form",
    rtlName: "فورم ",
    layout: "/phase2",

    component: RecreatePasword,
  },
];


 */


// var routes3 = [
//   {
//     path: "/my-data",
//     name: "My Data",
//     rtlName: "بياناتي",

//     component: MyData,
//     layout: "/phase3",
//   },
//   {
//     path: "/password",
//     name: "",
//     rtlName: "",
//     layout: "/phase3",

//     component: Password,
//   },
//   {
//     path: "/participations",
//     name: "My Posts",
//     rtlName: "مشاركاتي",
//     layout: "/phase3",

//     component: Participations2,
//   },
//   {
//     path: "/notifies",
//     name: "Notifies",
//     rtlName: " الإشعارات",
//     layout: "/phase3",

//     component: NotifiesPrev,
//   },
// ];
// var routes4 = [
//   {
//     path: "/competition",
//     name: "competition",
//     rtlName: "المسابقة",

//     component: Competition,
//     layout: "/phase4",
//   },
//   {
//     path: "/new-account",
//     name: "new-account",
//     rtlName: "المسابقة",

//     component: NewAccountForm,
//     layout: "/phase4",
//   },
//   {
//     path: "/login-account",
//     name: "login-account",
//     rtlName: "Login Account",

//     component: LoginAccountForm,
//     layout: "/phase4",
//   },
//   {
//     path: "/upload",
//     name: "Upload",
//     rtlName: "رفع الملفات",

//     component: UploadFiles,
//     layout: "/phase4",
//   },
//   {
//     path: "/policy",
//     name: "Policy",
//     rtlName: "الخصوصية",

//     component: PolicyPage,
//     layout: "/phase4",
//   },
//   {
//     path: "/codecheck",
//     name: "code",
//     rtlName: "code",

//     component: CodeCheck,
//     layout: "/phase4",
//   },
//   {
//     path: "/reject",
//     name: "reject",
//     rtlName: "رفض",

//     component: Reject,
//     layout: "/phase4",
//   },
//   {
//     path: "/success",
//     name: "success",
//     rtlName: "نجاح",

//     component: Success,
//     layout: "/phase4",
//   },
//   {
//     path: "/success-with-qr",
//     name: "success-with-qr",
//     rtlName: "نجاح",

//     component: SuccessWithQr,
//     layout: "/phase4",
//   },
//   {
//     path: "/racetitle",
//     name: "race",
//     rtlName: "المسابقة",

//     component: RaceTitle,
//     layout: "/phase4",
//   },
//   {
//     path: "/login-user",
//     name: "loginuser",
//     rtlName: "loginl",

//     component: LoginUser,
//     layout: "/phase4",
//   },
//   {
//     path: "/login-with-password",
//     name: "LoginWithPassword",
//     rtlName: "loginl",

//     component: LoginWithPassword,
//     layout: "/phase4",
//   },
//   {
//     path: "/login-with-code-form",
//     name: "LoginWithCodeForm",
//     rtlName: "loginl",

//     component: LoginWithCodeForm,
//     layout: "/phase4",
//   },
//   {
//     path: "/personal",
//     name: "personal",
//     rtlName: "loginl",

//     component: PersonalProfile,
//     layout: "/phase4",
//   },
//   {
//     path: "/Random",
//     name: "Random",
//     rtlName: "loginl",

//     component: Random,
//     layout: "/phase4",
//   },
// ];
// var routes5 = [
//   {
//     path: "/copons/resturants/details",
//     name: "Copon Details",
//     rtlName: "تفاصيل الكوبون",

//     component: CoponDetails,
//     layout: "/phase5",
//   },
//   {
//     path: "/copons/resturants",
//     name: "Resturants",
//     rtlName: "مطاعم",

//     component: Resturants,
//     layout: "/phase5",
//   },
//   {
//     path: "/copons",
//     name: "Copons",
//     rtlName: "الكوبونات",

//     component: Copons,
//     layout: "/phase5",
//   },
//   {
//     path: "/competitions/single/results",
//     name: "Comp Results",
//     rtlName: "نتائج المسابقة",

//     component: Result,
//     layout: "/phase5",
//   },
//   {
//     path: "/competitions/single",
//     name: "Comp Details",
//     rtlName: "تفاصيل المسابقة",

//     component: CompetitionDetails,
//     layout: "/phase5",
//   },

//   {
//     path: "/competitions",
//     name: "Competitions",
//     rtlName: "المسابقات",

//     component: Competitions,
//     layout: "/phase5",
//   },
// ];

export default { mainRoutes,ecoponroutes, authRoutes};
