import React from 'react'

export default function PasswordInput({placeholder, error, onChange}) {
  const [showPassword, setshowPassword] = React.useState(false);

    return (
        <>
             <input
                onChange={onChange}

                    type={showPassword ? "text" : "password"}
                    className={`form__input has-icon has-left-icon circle ${
                      error ? "error" : ""
                    }`}
                   placeholder={placeholder}
                    
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/img/lock.svg`}
                    alt={placeholder}
                    className="form__icon"
                  />
                  {showPassword ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/img/eye_2.svg`}
                      alt={placeholder}
                      onClick={() => setshowPassword(!showPassword)}
                      className="form__icon--left"
                    />
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/img/eye.svg`}
                      alt="cobon back"
                      onClick={() => setshowPassword(!showPassword)}
                      className="form__icon--left"
                    />
                  )}
        </>
    )
    
}
