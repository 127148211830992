import axios from "axios";
import { returnError } from "../helpers";
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, LOGIN_INIT } from "./types";
import authService from "../services/auth-service";

import axiosService from "../services/axios-service";

const axiosInstance = axiosService.getInstance();
export const checkAuth = () => (dispatch) => {
   
  if (authService.isAuthenticated()) {
   
    dispatch({
      type: LOGIN_SUCCESS,
      payload: authService.getToken(),
    });
  }
};
export const logout = () => {
  authService.removeToken();
  return {
    type: LOGOUT,
  };
};
export const loginSuccess = (res) => {
  authService.saveToken(res);

  return {
    type: LOGIN_SUCCESS,
    payload: res,
  };
};
export const loginfailure = (errors) => {
  return {
    type: LOGIN_FAILURE,
    payload: errors,
  };
};
///////auth
export const AppLogin =
  (countryId, countrycode, mobileNumber) => (dispatch) => {
     
    return axiosInstance
      .get(`/AppLogin/${countryId}/${countrycode}${mobileNumber}`)
      .then((res) => {
        return res.data.Data;
      })
      .catch((err) => {
        return Promise.reject(returnError(err));
      });
  };
export const AppValidatePassword = (params, password) => (dispatch) => {
  dispatch({ type: LOGIN_INIT });
   
  return axiosInstance
    .get(
      `/AppValidatePassword/${params?.countryId}/${params?.countryCode}${params?.mobile}/${password}`
    )
    .then((res) => {
      return res.data.Data;
    })
    .catch((err) => {
      return Promise.reject(returnError(err));
    });
};


export const GetConsumer =
  (consumerId) => (dispatch) => {
    return axiosInstance
      .get(`/GetConsumer/${consumerId}`)
      .then((res) => {
        return res.data.Data;
      })
      .catch((err) => {
        return Promise.reject(returnError(err));
      });
  };


  export const UpdateConsumerProfile =
  (data) => (dispatch) => {
     
    return axiosInstance
      .post(`/UpdateConsumerProfile`,{...data})
      .then((res) => {
        return res.data.Data;
      })
      .catch((err) => {
        return Promise.reject(returnError(err));
      });
  };
  

  export const GenerateOTP =
  (data) => (dispatch) => {
     
    
    return axiosInstance
      .post(`/GenerateOTP`,{...data})
      .then((res) => {
        return res.data.Data;
      })
      .catch((err) => {
        return Promise.reject(returnError(err));
      });
  };
  
  export const VerifyOTP =
  (data) => (dispatch) => {
     
    return axiosInstance
      .post(`/VerifyOTP`,{...data})
      .then((res) => {
        return res.data.Data;
      })
      .catch((err) => {
        return Promise.reject(returnError(err));
      });
  };
  export const MobileWebConsumerRegister  =
  (data) => (dispatch) => {
     
    return axiosInstance
      .post(`/MobileWebConsumerRegister`,{...data})
      .then((res) => {
        return res.data.Data;
      })
      .catch((err) => {
        return Promise.reject(returnError(err));
      });
  };
  export const RegisterCompany  =
  (data) => (dispatch) => {
     
    return axiosInstance
      .post(`/RegisterCompany`,{...data})
      .then((res) => {
        return res.data.Data;
      })
      .catch((err) => {
        return Promise.reject(returnError(err));
      });
  };
  export const CompanyLogin  =
  (data) => (dispatch) => {
     
    return axiosInstance
      .post(`/CompanyLogin`,{...data})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return Promise.reject(returnError(err));
      });
  };
  export const ResetPassword  =
  (data) => (dispatch) => {
     
    return axiosInstance
      .post(`/ResetPassword`,{...data})
      .then((res) => {
        return res.data.Data;
      })
      .catch((err) => {
        console.error(err)
        return Promise.reject(returnError(err));
      });
  };