import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";


import Select from "../../components/Select";
import { GetCountriesForRegistration } from "../../actions/generalActions";
import EmailInput from "../../components/EmailInput";
import Loading from "../../components/Loading";
import { GenerateOTP } from "../../actions/authActions";
import NumberInput from "../../components/NumberInput";




export default function RevertPasswordForm(props) {
  const { t } = useTranslation();
  const history = useHistory()
  const location = useLocation()
  const [text, setText] = React.useState("");
  const [flagsDropDown, setflagsDropDown] = React.useState({
    loading: true,
    list: [],
  });
  const [loadingSubmit, setloadingSubmit] = React.useState(false);
  const [country, setcountry] = React.useState({});
  const [formData, setformData] = React.useState({
    "Mobile":"",
    "EmailID":"",
    "OTPTypeID":3,
    "CountryID":183

  });

  const [error, seterror] = React.useState({
    message: "",
  });
  
  const dispatch = useDispatch();

  const disabled = () => {
    return (!formData.Mobile && !formData.EmailID) || loadingSubmit ;
  };

  //events
  const onSelectChange = (e) => {
    setformData({ ...formData, CountryID: e.CountryID });
    setcountry(e)
  };
  const onBtnClick = () => {
    if (!disabled()) {
      setloadingSubmit(true);
      dispatch(
        GenerateOTP(
          formData
        )
      )
    

        .then((e) => {
          setloadingSubmit(false);
          if(e.IsValid){
            history.push({
              pathname: "/web/auth/code",
              state: {
               data:{
                 ...formData,
                 "ConsumerID":location.state?.data?.ConsumerID,
                 "OTPID": e.OtpID,
               },
               revert:true
              },
            })
          } else{
            seterror({ message: "Error" });
          }
        
        })
        .catch((err) => {
          setloadingSubmit(false);
          seterror({ message: "Error" });
        });
    }
  };
  const onchange = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setformData({ ...formData, Mobile: e.target.value });
      if (e.target.value.length > 13) {
        seterror({ message: `${t("auth.phone-error")}` });
      } else {
        seterror({ message: `` });
      }
    }
  };

  // cycles
  React.useEffect(() => {
    setflagsDropDown({ ...flagsDropDown, loading: true });
    // call countries flags
    dispatch(GetCountriesForRegistration())
      .then((e) => {
        let _default =  e.find(ele=>ele.CountryID === 183)
        setflagsDropDown({ list: e, loading: false });
    setcountry(_default);
      })
      .catch((err) => {
        setflagsDropDown({ list: [], loading: false });
      });
  }, [i18next.languages]);
  return (
    <div className="phone-form">
      <h1 className="auth__title">{t("auth.revert.title")} </h1>
      <h3 className="auth__subtitle">{t("auth.revert.sub")}</h3>

      <div className="auth__box">
        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="pos-rel">
            <EmailInput
                  placeholder={t("placeholders.email")}
                  changeEvent={(e) => {
                    setformData({ ...formData, EmailID: e });

                  }}
                  value={formData.EmailID}
                  icon={true} />
            </div>
          </div>
        </div>
        <div className="form__lines">
          <div></div>
          <span>{t("auth.or")}</span>
          <div></div>
        </div>
        <div className="form__group mg-top big-btm">
        <div className="row row--no-padding auth__group">
            <div className="input pos-rel">
            <NumberInput
                placeholder={t("placeholders.tele")}
                changeEvent={(value) => {
                  setformData({ ...formData, Mobile: value });

                }}
                value={formData.Mobile}
              />
            </div>
            <div className="select-wrapper">
              <Select
                className="form-select"
                classNamePrefix="select"
                placeholder={
                  flagsDropDown.loading
                    ? t("placeholders.load")
                    : t("placeholders.pick")
                }
            
                value={country}
                name="questions"
                // isSearchable={true}
                // isLoading={true}
                options={flagsDropDown.list}
                onChange={onSelectChange}
                getOptionLabel={(option) => (
                  <div className="select__withImg">
                    <img
                      src={`${option.FlagImage}`}
                      height="28px"
                      width="28px"
                    />
                    <span>{option.CountryCode}</span>{" "}
                  </div>
                )}
                getOptionValue={(option) => option.CountryID}
               
              />
            </div>
          </div>
        </div>

        <div className="form__group no-margin">
          <a
           onClick={onBtnClick}
            className={` btn bold custom-padding ${
              disabled() ? "btn--disabled" : "btn--green"
            } w-100`}
          >
             {loadingSubmit ? (
              <Loading smMargin={true} white={true} />
            ) : (
              t("placeholders.send")
            )}
          </a>
        </div>
      </div>
    </div>
  );
}
