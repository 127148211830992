import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory} from "react-router-dom";
import EmailInput from "../../components/EmailInput";

export default function FinishForm() {
  const [text, setText] = React.useState("");
  const [email, setemail] = React.useState("");
  const [name, setname] = React.useState("");
  const [emailError, setemailError] = React.useState(false);
  const [params, setparams] = React.useState({});

  const onchange = (e) => {
    setText(e.target.value);
  };
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const onChangeEmail = (e) => {
    setemail(e.target.value);
  };

  React.useEffect(() => {
    if (location.state) {
      setparams(location.state.data);
    }
  }, []);

  return (
    <div className="phone-form">
      <h1 className="auth__title">{t("auth.finish.title")}</h1>
      <h3 className="auth__subtitle">{t("auth.finish.sub")}</h3>

      <div className="auth__box">
        <div className="form__group ">
          <div className="row row--no-padding">
            <div className="pos-rel">
              <input
                value={params.FullName}
                type="text"
                disabled
                className={`form__input has-icon  circle `}
                placeholder={t("placeholders.full-name")}
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/user_2.svg`}
                alt="cobon user name"
                className="form__icon"
              />
            </div>
          </div>
        </div>

        <div className="form__group big-btm">
          <div className="row row--no-padding">
            <div className="pos-rel">
              <EmailInput
                disabled
                icon
                value={params.EmailID}
                placeholder={t("placeholders.email")}
              />
            </div>
          </div>
        </div>
        <div className="form__group ">
          <a
           onClick={(e)=>{
         history.push({
              pathname: "/web/auth/register",
              state: {
                data: params,
                third:true
              },
            });
           }}
            className={` btn bold custom-padding  btn--yellow has-shadow
            w-100`}
          >
            {t("auth.finish.continue")}{" "}
          </a>
        </div>

        <div className="form__lines">
          <div></div>
          <span>{t("auth.or")}</span>
          <div></div>
        </div>

        <div className="form__group big-btm mg-top">
          <a
             onClick={(e)=>{
              history.push({
                   pathname: "/web/auth/register",
                   state: {
                    mobile: params.MobileNumber
                  },
                 });
                }}
            className={` btn bold custom-padding  btn--green
              
            w-100`}
          >
            {t("auth.finish.register")}{" "}
          </a>
        </div>

        <div
          className="form__footer"
          style={{
            marginTop: "2px",
          }}
        >
          <div className="d-center">
            <div className=" text-right   col-padding-small flex-center">
              <p className="">{t("auth.finish.footer")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
