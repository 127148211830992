import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import i18Next from "i18next";

import Modal from "../../components/Modal";
import { GetNotifications,ReadNotification,updateNotificationBadge } from "../../actions/generalActions";
import Loading from "../../components/Loading";

export default function MyData() {
  const dummyList = [1, 1, 1, 11, 1, 1, 1, 1, 1, 1, 1];
  const [notifies, setnotifies] = React.useState({
    loading: false,
    list: [],
  });
  
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [clockedNotification, setclockedNotification] = React.useState({});

  
  const { isAuth, userData } = useSelector((state) => state.auth);

  const [text, setText] = React.useState("");
  function onNotificationClick(not){
    dispatch(ReadNotification(not.ConsumerNotificationID,userData.ConsumerID)).then(e=>console.log(e))
    setclockedNotification(not)
    openModal()
  }
  function openModal() {
    setIsOpen(true);
  }
  
  function closeModal() {
    setIsOpen(false);
  }
  const { t } = useTranslation();

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (isAuth) {
      setnotifies({ ...notifies, loading: true });

      // call notifies
      dispatch(GetNotifications(userData.ConsumerID))
        .then((e) => {
          setnotifies({ list: e, loading: false });
        dispatch(updateNotificationBadge(0))

           
        })
        .catch((err) => {
          setnotifies({ list: [], loading: false });
        });
    }
  }, [isAuth, i18Next.languages]);
 
  return (
    <div className="notifies">
     <Modal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        title={""}
      >
        <div className="new-account-form message">
          <div className="message-box no-icon">
            <div
              className="flex-center"
              style={{
                width: "100%",
                height: "auto",
                // background: "#f0f0f1",
                borderRadius: "8px",
                marginBottom: "2rem",
              }}
            >
              {clockedNotification.BarcodeImage ? <img
                style={{
                width:"auto",
                height:"auto",
                  marginBottom: "0",
                }}
                src={`${clockedNotification.BarcodeImage}`}
                alt="message"
              /> : clockedNotification.Barcode}
            </div>
            <a onClick={closeModal} className="btn btn--green w-100">
              {t("phase4.btn_agree")}
            </a>
          </div>
        </div>
      </Modal>
    
      <div className="row">
        <div className="goBack-btn right top hidden-mobile flex">
          <img
            src={`${process.env.PUBLIC_URL}/img/chevron-left_blue.svg`}
            alt="cobon back"
          />

          <Link to="/" className="btn btn--normal btn--normal-blue ">
            {t("auth.back")}
          </Link>
        </div>
        <div className="goBack-btn right top hidden-desktop">
          <img
            src={`${process.env.PUBLIC_URL}/img/chevron-left_white.svg`}
            alt="cobon back"
          />

          <Link to="/" className="btn btn--normal btn--normal-white ">
            {t("auth.back")}
          </Link>
        </div>
        {notifies.loading ? (
          <Loading />
        ) : (
          <div className="notifies__box">
            {notifies.list.map((not, i) => (
              <div className="notification"
              onClick={()=>onNotificationClick(not)}
              >
                <div className="notification__img">
                  <div className="notification__img--box">
                    {not.ImageUrl ? (
                      <img src={`${not.ImageUrl}`} alt={`${not.Subject}`} />
                    ) : (
                      <span>صورة الباركود</span>
                    )}
                  </div>
                  <span className="date">{not.SendingDate}</span>
                </div>
                <div className="notification__text">
                  <h1>{not.Subject}</h1>
                  <p className="hidden-mobile">{not.Message}</p>
                  <p className="hidden-desktop">{not.Message}</p>
                  <span>{not.SendingDate}</span>
                </div>
                <div className="notification__arrow">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/chevron-left.svg`}
                    alt="notify"
                  />
                </div>
              </div>
            ))}
          </div>
        )}{" "}
      </div>
    </div>
  );
}
