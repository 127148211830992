import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import PasswordInput from "../../components/PasswordInput";
import { ResetPassword } from "../../actions/authActions";
import Loading from "../../components/Loading";


export default function AuthPage() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [passwordConfirm, setpasswordConfirm] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [submitData, setsubmitData] = React.useState({
    loading: false,
    error: "",
  });

  const onBtnClick = (e) => {
    if (!disabled()) {
      setsubmitData({ error: "", loading: true });
      let data = location.state.data;
      let params = {
        Mobile: data.Mobile,
        Email: "",
        NewPassword: password,
        ConsumerID: data.ConsumerID,
        CountryID: data.CountryID,
      };
      dispatch(ResetPassword(params))
        .then((res) => {
          if (res.ResetPassword === "1") {
            setsubmitData({ error: "", loading: false });

            history.push({
              pathname: "/web/auth",
            });
          } else {
            setsubmitData({ error: t("forms.error"), loading: false });
          }
        })
        .catch((err) => {
          setsubmitData({ error: t("forms.error"), loading: false });
        });
    }
  };
  const disabled = () =>
    !password || !passwordConfirm || passwordConfirm !== password;
 
  const { t } = useTranslation();
  return (
    <div className="phone-form">
      <h1 className="auth__title">{t("auth.recreate.title")} </h1>
      <h3 className="auth__subtitle">{t("auth.recreate.sub")}</h3>

      <div className="auth__box">
        <div className="form__group ">
          <div className="row row--no-padding">
            <div className=" col-padding-small pos-rel">
              <PasswordInput
                onChange={(e) => {
                  setpassword(e.target.value);
                }}
                value={password}
                placeholder={t("placeholders.passord")}
              />
            </div>
          </div>
        </div>

        <div className="form__group big-btm">
          <div className="row row--no-padding">
            <div className=" pos-rel">
              <PasswordInput
                onChange={(e) => {
                  setpasswordConfirm(e.target.value);
                }}
                value={passwordConfirm}
                placeholder={t("placeholders.confirm")}
              />
            </div>
          </div>
        </div>
        {submitData.error && (
          <div className="mg-btm">
            <span className="error-text">{submitData.error}</span>
          </div>
        )}
        <div className="form__group mg-top">
          <a
            onClick={onBtnClick}
            className={` btn bold custom-padding ${
              disabled() ? "btn--disabled" : "btn--green"
            } w-100`}
          >
           {" "}
            {submitData.loading ? (
              <Loading white smMargin />
            ) : (
              t("placeholders.send")
            )}{" "}
           
          </a>
        </div>
      </div>
    </div>
  );
}
