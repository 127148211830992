import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../components/Navbar";
import Hero from "../components/Hero";

import { Route, Switch, Redirect } from "react-router-dom";

export default withRouter(function Phase4({ routes, children, location }) {
  const [height, setHeight] = React.useState(50);
  const ref = React.useRef(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    // check height of the content to style the footer always on bottom
    setTimeout(() => {
      const h = ref.current.clientHeight - (window.innerHeight - 130);
      setHeight(h);
    
    }, 100);
  });
  return (
    <div>
      <Navbar phase4={true} routes={routes} />

      <div ref={ref} className="page-body top-distance">
        <Switch>
          {routes.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                render={(props) => (
                  <prop.component {...props} title={`${prop.rtlName}`} />
                )}
                key={key}
              />
            );
          })}
          <Redirect from="/phase4" to="/phase4/competition" />
        </Switch>
      </div>
      <div
        style={{
          position: `${height > 0 ? "relative" : "absolute"}`,
          bottom: "0",
          width: "100%",
        }}
        className={`page-footer small`}
      >
        <footer className="footer small">
          <div className="row">
            <div className="flex-center">
              <p>{t("phase4.footer")} </p>
              <Link to="/" className="btn btn-text hovered">
                e-copon
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
});
