import React from 'react'

export default function Button({white,smMargin}) {
    return (
        <div className={` dots loading  ${white?'white': ''} ${smMargin?'smMargin': ''}`}>
            <span className="dot _1"></span>
            <span className="dot _2 active"></span>
            <span className="dot _3"></span>
          </div>
    )
}
