import React from "react";
import { useTranslation } from "react-i18next";
import i18Next from "i18next";
import { useDispatch, useSelector } from "react-redux";



import Table from "../../components/Table";
import { MyParticipations } from "../../actions/generalActions";


export default function Participations() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { isAuth, userData } = useSelector((state) => state.auth);

  const [empty, setempty] = React.useState(false);
  const [participations, setparticipations] = React.useState({
    loading: false,
    list: [],
  });
  const columns = [
    { name: "#" ,valueProperty:'id' , id:true},
    { name: `${t("phase3.table.name")}` ,valueProperty:'EService'},
    { name: `${t("phase3.table.code")}`,valueProperty:'ECouponParticipationID' },
    { name: `${t("phase3.table.start")}`,valueProperty:'FromDate' , date:true },

    { name: `${t("phase3.table.end")}`,valueProperty:'ToDate' , date:true },
    { name: `${t("phase3.table.withdraw")}`,valueProperty:'ResultDate' , date:true },
  ];
  const data = {
    loading:false,
    list:[
      {
        "#": "1",
        [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
        [t("phase3.table.code")]: "45698522358",
        [t("phase3.table.start")]: "28/1/2021",
        [t("phase3.table.end")]: "28/1/2021",
        [t("phase3.table.withdraw")]: "28/1/2021",
      },
      {
        "#": "2",
        [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
        [t("phase3.table.code")]: "45698522358",
        [t("phase3.table.start")]: "28/1/2021",
        [t("phase3.table.end")]: "28/1/2021",
        [t("phase3.table.withdraw")]: "28/1/2021",
      },
      {
        "#": "3",
        [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
        [t("phase3.table.code")]: "45698522358",
        [t("phase3.table.start")]: "28/1/2021",
        [t("phase3.table.end")]: "28/1/2021",
        [t("phase3.table.withdraw")]: "28/1/2021",
      },
      {
        "#": "4",
        [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
        [t("phase3.table.code")]: "45698522358",
        [t("phase3.table.start")]: "28/1/2021",
        [t("phase3.table.end")]: "28/1/2021",
        [t("phase3.table.withdraw")]: "28/1/2021",
      },
      {
        "#": "5",
        [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
        [t("phase3.table.code")]: "45698522358",
        [t("phase3.table.start")]: "28/1/2021",
        [t("phase3.table.end")]: "28/1/2021",
        [t("phase3.table.withdraw")]: "28/1/2021",
      },
      {
        "#": "6",
        [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
        [t("phase3.table.code")]: "45698522358",
        [t("phase3.table.start")]: "28/1/2021",
        [t("phase3.table.end")]: "28/1/2021",
        [t("phase3.table.withdraw")]: "28/1/2021",
      },
      {
        "#": "7",
        [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
        [t("phase3.table.code")]: "45698522358",
        [t("phase3.table.start")]: "28/1/2021",
        [t("phase3.table.end")]: "28/1/2021",
        [t("phase3.table.withdraw")]: "28/1/2021",
      },
      {
        "#": "8",
        [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
        [t("phase3.table.code")]: "45698522358",
        [t("phase3.table.start")]: "28/1/2021",
        [t("phase3.table.end")]: "28/1/2021",
        [t("phase3.table.withdraw")]: "28/1/2021",
      },
      {
        "#": "9",
        [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
        [t("phase3.table.code")]: "45698522358",
        [t("phase3.table.start")]: "28/1/2021",
        [t("phase3.table.end")]: "28/1/2021",
        [t("phase3.table.withdraw")]: "28/1/2021",
      },
      {
        "#": "10",
        [t("phase3.table.name")]: "ABDALGHANE MASOUDI",
        [t("phase3.table.code")]: "45698522358",
        [t("phase3.table.start")]: "28/1/2021",
        [t("phase3.table.end")]: "28/1/2021",
        [t("phase3.table.withdraw")]: "28/1/2021",
      },
    ]
  }
 
  React.useEffect(() => {
    if(isAuth){
      setparticipations({ ...participations, loading: true });

    // call participations
    dispatch(MyParticipations(userData.ConsumerID))
      .then((e) => {
        setparticipations({ list: e, loading: false });
         
      })
      .catch((err) => {
        setparticipations({ list: [], loading: false });
      });
    }
  }, [isAuth,i18Next.languages]);
  return (
    <div className="participations">
      <div className="row">
        <div className="participations__header">
          <h1 className="page-title no-margin">{t("phase3.participation")}</h1>
        </div>
        <div className="participations__wrapper">
          <div className="participations__table">
            <Table empty={empty} columns={columns} data={participations} />
          </div>
        </div>
      </div>
    </div>
  );
}
