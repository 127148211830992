import React from "react";
import i18Next from "i18next";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Copons from "../Views/phase5/Copons";
import useWindowDimensions from "../helpers/JsMediaQuery";
import {tabFunctions} from "../helpers/Tabs";


export default withRouter(function Tabs(props) {
  
  React.useEffect(() => {
    
    setTimeout(() => {
      document.getElementById("parent-id")&& tabFunctions(i18Next);
    }, 100);
  }, [ i18Next.languages]);

  React.useEffect(() => {
    function handleResize() {
      
      tabFunctions(i18Next);
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    window.removeEventListener("load", handleResize);
    };


  },[]);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  return (
    <div>
      <div className="tabs">
        <div id="parent-id" className="row ">
          <div className="tabs-head">
            <div className={`flex-between  w-100 ${width > 550?'':'justify-center'}`}>
              <div className={`tabs__nav  ${width > 550?'':'center'}`} id="tabs__nav">
                <ul className="tabs__nav-list">
                  {props.tabs.map((e, i) => (
                    <li
                      key={i}
                      className={`tabs__nav-item ${i === 0 ? "js-active" : ""}`}
                    >
                      {e.name}
                    </li>
                  ))}
                </ul>
                <div id="decore"></div>
              </div>

            </div>
          </div>
        </div>
        <div className="tabs__panels">
          {props.tabs.map((e, i) => (
            <div key={i} className="tabs__panel">
              <div className="row  w-100  position-relative">
              {width > 550 && <div className="show-all flex-center panel-related">
                  <Link to={`${e.linkTo}`} className="btn btn-text btn-text--white ">
                    {t("home.tabs.show")}
                  </Link>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/chevron-left.svg`}
                    alt=""
                  />
                </div>}
             
              </div>
              {e.content}
            {width <= 550 &&  <div className="show-all flex-center mg-top-big">
            <Link to={`${e.linkTo}`} className="btn btn-text btn-text--white ">
              {t("home.tabs.show")}
            </Link>
            <img
              src={`${process.env.PUBLIC_URL}/img/chevron-left.svg`}
              alt=""
            />
          </div>}
            </div>
          ))}
        </div>
     
      </div>
    </div>
  );
});
