export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_INIT = "LOGIN_INIT";
export const LOGOUT = "LOGOUT";





//general strings
export const LOGIN_REDIRECTION_URL = "redir";

