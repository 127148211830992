import React from "react";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { GetUserTerms, SaveECoupons } from "../../../actions/generalActions";
import Modal from "../../../components/Modal";
import Loading from "../../../components/Loading";
import { GetNotifications } from "../../../actions/generalActions";


export default function CodeCheck({
  modalIsOpen,
  openModal,
  closeModal,
  userData,
}) {
  const dispatch = useDispatch();
  let { coponId, serviceId } = useParams();

  const [text, setText] = React.useState("");
  const [checked, setchecked] = React.useState(false);
  const [userTermsModalOpen, setuserTermsModalOpen] = React.useState(false);
  const [successModelOpen, setsuccessModelOpen] = React.useState(false);

  const { t } = useTranslation();
  const [userTerms, setuserTerms] = React.useState({
    loading: false,
    text: "",
  });
  const [submitCode, setsubmitCode] = React.useState({
    loading: false,
    error: "",
  });

  const disabled = () => !checked || !text;

  //events
  const onCodeCheckClick = () => {
    if (!disabled()) {
      setsubmitCode({ error: "", loading: true });
      let visitorData = JSON.parse(localStorage.getItem("visitor"));

      let data = {
        Barcode: text,
        ConsumerID: userData.ConsumerID,
        EcouponID: coponId,
        WrongAnswer: 0,
        EServiceID: serviceId,
        Latitude: visitorData.latitude,
        Longitude: visitorData.longitude,
        MacAddress: visitorData.ip,
      };

      dispatch(SaveECoupons(data))
        .then((e) => {
 
          setsubmitCode({ loading: false, error: "" });
          
          switch (e.Success) {
            case 1:
              setsubmitCode({ loading: false, error: "" });
              closeModal()
              setsuccessModelOpen(true)
              dispatch(GetNotifications(userData.ConsumerID))
              break;
            case 0:
              setsubmitCode({ loading: false, error: e.Message });

              break;

            default:
              break;
          }
        })
        .catch((err) => {
          setsubmitCode({ error: t("phase3.error"), loading: false });
        });
    }
  };
  
  const onUserTermsAgree = () => {
   
    setchecked(true);
    setuserTermsModalOpen(false);
  };
  const onUserTermsCancel = () => {
    setchecked(false);

    setuserTermsModalOpen(false);
  };
  const onUserTermsClick = () => {
    setuserTermsModalOpen(true);
    setuserTerms({ text: "", loading: true });

    dispatch(GetUserTerms(coponId))
      .then((e) => {
        setuserTerms({ loading: false, text: e.EcouponDetails[0]?.UsesTerm });
      })
      .catch((err) => {
        setuserTerms({ text: t("phase3.error"), loading: false });
      });
  };
  return (
    <div className="">
       <Modal
        closeModal={()=>setsuccessModelOpen(false)}
        openModal={()=>setsuccessModelOpen(true)}
        modalIsOpen={successModelOpen}
        title={t("phase4.success")}
      >
        <div className="new-account-form message">
          <div className="message-box">
            <img
              src={`${process.env.PUBLIC_URL}/img/check.svg`}
              alt="message"
            />
            <p>{t("phase4.success_message")}</p>
            <a onClick={()=>setsuccessModelOpen(false)} className="btn btn--green w-100">
              {t("phase4.btn_agree")}
            </a>
          </div>
        </div>
      </Modal>
      <Modal
        closeModal={closeModal}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        title={t("phase4.code_check")}
      >
        <div className="new-account-form">
          <form >
            <div className="form__group big-btm">
              <div className="row row--no-padding">
                <div className="pos-rel">
                  <input
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    type="text"
                    className={`form__input circle `}
                    placeholder={t("placeholders.code-input")}
                  />
                </div>
              </div>
            </div>
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="pos-rel">
                  <input
                    type="checkbox"
                    onChange={(e) => setchecked(e.currentTarget.checked)}
                    id="chekbox"
                    name="chekckbox"
                    checked={checked}
                    // value={checked}
                  />
                  <label for="chekbox">
                    <div className="check">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/check_white.svg`}
                        height="11px"
                        width="11px"
                      />
                    </div>
                    <span
                      className="checkbox-span"
                      style={{ fontSize: "1.5rem" }}
                    >
                      {t("phase4.agree")}
                    </span>{" "}
                    <a
                      style={{ fontSize: "1.5rem" }}
                      onClick={onUserTermsClick}
                      className="btn btn-text hovered"
                    >
                      {t("phase4.policy")}
                    </a>
                  </label>
                </div>
              </div>
            </div>
            <div className="form__group ">
              {submitCode.error && (
                <p className="mg-top-middle mg-btm comp-details__error">
                  {submitCode.error}
                </p>
              )}
              <a
                onClick={onCodeCheckClick}
                className={` btn bold custom-padding ${
                  disabled() ? "btn--disabled" : "btn--green"
                } w-100`}
              >
                {submitCode.loading ? (
                  <Loading white smMargin />
                ) : (
                  t("phase4.code_check")
                )}{" "}
              </a>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        closeModal={() => setuserTermsModalOpen(false)}
        openModal={() => setuserTermsModalOpen(true)}
        modalIsOpen={userTermsModalOpen}
        title={t("phase4.policypage.title")}
      >
        <div className="new-account-form ">
          <div className="policy">
            <div className="policy__content">
              {userTerms.loading ? (
                <Loading />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: userTerms.text }}></p>
              )}
            </div>
            <div className="policy__btns">
              <a onClick={onUserTermsAgree} className="btn btn--yellow first">
                {t("phase4.btn_agree")}
              </a>
              <a
                onClick={onUserTermsCancel}
                className="btn btn--disabled reject"
              >
                {t("phase4.cancel")}
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
