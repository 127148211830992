import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import Select from "../../components/Select";
import { GetCountriesForRegistration } from "../../actions/generalActions";
import { AppLogin } from "../../actions/authActions";
import Loading from "../../components/Loading";
import { LOGIN_REDIRECTION_URL } from "../../actions/types";

export default function AuthPage(props) {
  const [flagsDropDown, setflagsDropDown] = React.useState({
    loading: true,
    list: [],
  });
  const [loadingSubmit, setloadingSubmit] = React.useState(false);
  const [formData, setformData] = React.useState({
    phone: "",
    country: {},
  });

  const [error, seterror] = React.useState({
    message: "",
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const disabled = () => {
    return formData.phone.length < 1 || !formData.country.CountryID || loadingSubmit;
  };

  //events
  const onSelectChange = (e) => {
    setformData({ ...formData, country: e });
  };
  const onBtnClick = () => {
    if (!disabled()) {
      setloadingSubmit(true);
      // to="/web/auth/code"
      dispatch(
        AppLogin(
          formData.country?.CountryID,
          formData.country?.CountryCode,
          formData.phone
        )
      )
        .then((e) => {
          setloadingSubmit(false);
          switch (e.Status) {
            case 0:
              seterror({ message: `` });
            props.history.push({
              pathname: "/web/auth/code",
              state: {
                data: {...e, CountryID:formData.country?.CountryID},
              },
            });
              break;
            case 1:
              seterror({ message: `` });

              props.history.push({
                pathname: "/web/auth/password",
                state: {
                  data: {
                    mobile: formData.phone,
                    consumerId  : e.ConsumerID,
                    OtpID  : e.OtpID,
                    countryId: formData.country?.CountryID,
                    countryCode: formData.country?.CountryCode,
                  },
                },
              });
              break;
            case 2:
              seterror({ message: `` });
              props.history.push({
                pathname: "/web/auth/code",
                state: {
                  data: {...e, CountryID:formData.country?.CountryID},
                  third:true
                },
              });
                break;

            case 3:
              seterror({ message: `${t("auth.wrongecountry")}` });

              break;
            case 4:
              seterror({ message: `${t("auth.wrongNumber")}` });

              break;
            case 5:
              seterror({ message: `Generic Error` });
              break;

            default:
              break;
          }
        })
        .catch((err) => {
          setloadingSubmit(false);
          seterror({ message: "Error" });
        });
    }
  };
  const onchange = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setformData({ ...formData, phone: e.target.value });
      if (e.target.value.length > 13) {
        seterror({ message: `${t("auth.phone-error")}` });
      } else {
        seterror({ message: `` });
      }
    }
  };

  // cycles
  React.useEffect(() => {
    setflagsDropDown({ ...flagsDropDown, loading: true });
    // call countries flags
    dispatch(GetCountriesForRegistration())
      .then((e) => {
        let _default =  e.find(ele=>ele.CountryID === 183)
        setflagsDropDown({ list: e, loading: false });
    setformData({ ...formData, country: _default });

      })
      .catch((err) => {
        setflagsDropDown({ list: [], loading: false });
      });
  }, [i18next.languages]);

  return (
    <div className="phone-form">
      <h1 className="auth__title">{t("auth.login")}</h1>
      <h3 className="auth__subtitle">{t("auth.please-enter-phone")}</h3>

      <div className="auth__box">
        <div className="form__group big-btm">
          <div className="row row--no-padding auth__group">
            <div className="input pos-rel">
              <input
                onChange={onchange}
                value={formData.phone}
                type="text"
                className={`form__input has-icon circle ${
                  error.message ? "error" : ""
                }`}
                placeholder={t("placeholders.tele")}
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/phone.svg`}
                alt="cobon back"
                className="form__icon"
              />

              <label className="form__label">{t("placeholders.tele")} </label>
            </div>
            <div className="select-wrapper">
              <Select
                className="form-select"
                classNamePrefix="select"
                placeholder={
                  flagsDropDown.loading
                    ? t("placeholders.load")
                    : t("placeholders.pick")
                }
                defaultValue={
                  flagsDropDown.loading ? "..." : flagsDropDown.list[0]
                }
                name="questions"
                // isSearchable={true}
                // isLoading={true}
                options={flagsDropDown.list}
                onChange={onSelectChange}
                getOptionLabel={(option) => (
                  <div className="select__withImg">
                    <img
                      src={`${option.FlagImage}`}
                      height="28px"
                      width="28px"
                    />
                    <span>{option.CountryCode}</span>{" "}
                  </div>
                )}
                getOptionValue={(option) => option.CountryID}
                value={formData.country}
              />
            </div>
          </div>
        </div>

        {error.message && <p className="error-text">{error.message}</p>}
        <div className="form__group no-margin">
          <a
            onClick={onBtnClick}
            className={` btn bold custom-padding ${
              disabled() ? "btn--disabled" : "btn--green"
            } w-100`}
          >
            {loadingSubmit ? (
              <Loading smMargin={true} white={true} />
            ) : (
              t("placeholders.next")
            )}
          </a>
        </div>
      </div>
    </div>
  );
}
