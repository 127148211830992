import React from 'react'
import { useHistory,useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { logout } from '../../actions/authActions'
import { LOGIN_REDIRECTION_URL } from '../../actions/types'




export default function Logout() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  React.useEffect(() => {
    dispatch(logout())
    let redir = sessionStorage.getItem(LOGIN_REDIRECTION_URL);
    setTimeout(() => {
     if (redir) {
       sessionStorage.removeItem(LOGIN_REDIRECTION_URL);
       history.replace(redir);
     } else {
       history.replace("/");
     }
    }, 100);
  }, [])
  return null
  
}
