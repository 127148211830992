import React from 'react'
import { useTranslation } from 'react-i18next'
import i18Next from 'i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'



import { GetPrivacyAndTerms } from '../../actions/generalActions'
import Loading from '../../components/Loading'




export default function Rules() {
    const {t}= useTranslation()
    const dispatch = useDispatch()
    const location = useLocation()
    const [rules, setrules] = React.useState({
        loading: false,
        list: [],
      });
    React.useEffect(() => {
      
          setrules({ ...rules, loading: true });
    
        // call rules
        dispatch(GetPrivacyAndTerms(location.state.type || 1))
          .then((e) => {
            setrules({ list: e, loading: false });
             
          })
          .catch((err) => {
            setrules({ list: [], loading: false });
          });
   
      }, [i18Next.languages]);
    
    return (
        <div className="row">
               {
                   rules.loading ?
                   <Loading />
                   : <div className="rules">
                        {rules.list.length>1 ? rules.list.map((rule,i)=>(
                            <>  <h1  key={i}>{rule.Heading}                            </h1>
                            <p style={{
                                marginBottom:'3rem'

                            }}>
                            {rule.Content} 
                                </p></>
                        )):
                   <p>No Data Found</p>
                   }
               </div>
               }
        </div>
    )
}
