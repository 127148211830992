import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import i18Next from "i18next";

import { GetCoponInformation } from "../../actions/generalActions";
import Loading from "../../components/Loading";
import Breadcrumb from "../../components/Breadcrumb";
import Modal from "../../components/Modal";



export default function Competitions() {
  const [comps, setcomps] = React.useState({
    loading: false,
    list: [],
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const { t } = useTranslation();

  const dispatch = useDispatch();
  React.useEffect(() => {
    setcomps({ ...comps, loading: true });

    // call comps
    dispatch(GetCoponInformation(0,0,0))
      .then((e) => {
        setcomps({ list: e.eCopon, loading: false });

      })
      .catch((err) => {
        setcomps({ list: [], loading: false });
      });
  }, [i18Next.languages]);
  const card = (e) => (
    <div key={e.ECouponID} className="competitions__row--col">
      <div className="competitions__card  soon">
      { e.CompetitionStatus ==='2' &&   <div className="competitions__card--badge end">
          <span>{t("phase4.comp.completed")}</span>
        </div>}
        { e.CompetitionStatus ==='3' &&   <div className="competitions__card--badge soon">
          <span>{t("phase4.comp.soon")}</span>
        </div>}
        <div className="competitions__card--img">
          <img src={`${e.ImageFile}`} alt={`${e.ServiceName}`} />
        </div>
        <div className="competitions__card--content">
         
        <Link
            to={`/web/ecopon/competitions/${e.ECouponID}/${e.EServiceID}`}
            className="btn btn-link pd-0"
          >
            <h1 className="">{e.ServiceName}</h1>
          </Link>
          <h4 className="price ">
            {e.PrizeCount} <span>{t("phase5.card.price")}</span>
          </h4>
        </div>
      </div>
    </div>
  );
  return (
    <>
       
   <Breadcrumb />
      <div className="competitions competitions-mobile">
        <div className="row">
       
          <div className="competitions__row ">
            {comps.loading ? <Loading /> : comps.list.map((e, i) => card(e))}
          </div>
        </div>
      </div>
    </>
  );
}
