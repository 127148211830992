import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Select from "../../components/Select";
import EmailInput from "../../components/EmailInput";
import PasswordInput from "../../components/PasswordInput";
import { CompanyLogin } from "../../actions/authActions";
import Loading from "../../components/Loading";
import NumberInput from "../../components/NumberInput";


export default function LoginCompany() {

  const [crNumber, setcrNumber] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [submitData, setsubmitData] = React.useState({
    loading: false,
    error: "",
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const disabled = () => !crNumber || !password || submitData.loading;

  const onBtnClick = (e) => {
    if (!disabled()) {
      setsubmitData({ error: "", loading: true });
      let data = {
        UserName: crNumber,
        Password: password,
      };
      dispatch(CompanyLogin(data))
        .then((res) => {
          if (res.ResponseHeader.Status === 1) {
            setsubmitData({ error: "", loading: false });
            window.open(res.DashboardUrl);
            history.push({
              pathname: "/web/main/home",
            });
          } else {
            setsubmitData({ error: res.ResponseHeader.Message, loading: false });
          }
        })
        .catch((err) => {
          setsubmitData({ error: t("forms.error"), loading: false });
        });
    }
  };
  const { t } = useTranslation();

  return (
    <div className="login-company-form">
      <h1 className="auth__heading">{t("auth.heading")}</h1>
      <h1 className="auth__title"> {t("auth.login")}</h1>
      <h3 className="auth__subtitle"> {t("auth.please-login")}</h3>

      <div className="auth__box">
        <div className="form__group ">
          <div className="row row--no-padding ">
            <div className="input pos-rel">
              <NumberInput
                placeholder={t("placeholders.crNumber")}
                changeEvent={(e) => {
                  setcrNumber(e);
                }}
                value={crNumber}
                icon={true}
                normalNumber
              />
            </div>
          </div>
        </div>
        <div className="form__group big-btm">
          <div className="row row--no-padding ">
            <div className="input pos-rel">
              <PasswordInput
                onChange={(e) => {
                  setpassword(e.target.value);
                }}
                value={password}
                placeholder={t("placeholders.passord")}
              />
            </div>
          </div>
        </div>
        {submitData.error && (
            <div className="mg-btm">
              <span className="error-text">{submitData.error}</span>
            </div>
          )}
        <div className="form__group btm">
          <a
           onClick={onBtnClick}
            className={` btn bold custom-padding ${
              disabled() ? "btn--disabled" : "btn--green"
            } w-100`}
          >
            {submitData.loading ? (
              <Loading white smMargin />
            ) : (
              t("placeholders.next")
            )}{" "}
          </a>
        </div>
        <div className="form__footer pd-top">
          <div className="flex-center wrap_center">
            <div className=" col-padding-small">
              <span>{t("auth.noAccount")}</span>
            </div>
            <div className=" col-padding-small">
              <Link
                to="/web/auth/registerCompany"
                className="btn btn-text hovered"
              >
                {t("auth.create")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
