import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Breadcrumb from "../../components/Breadcrumb";
import i18Next from "i18next";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import { GetVoucherListing } from "../../actions/generalActions";
import Loading from "../../components/Loading";

export default function Retsurants() {
  const [vouchersListings, setvoucherListings] = React.useState({
    loading: false,
    data: null,
  });

  let { categoryId,categoryName } = useParams();
  const dispatch = useDispatch();
  React.useEffect(() => {
    setvoucherListings({ ...vouchersListings, loading: true });

    // call vouchersListings
    dispatch(GetVoucherListing(100, categoryId))
      .then((e) => {
        setvoucherListings({ data: e.ResponseVoucherListing || null, loading: false });

      })
      .catch((err) => {
        setvoucherListings({ data: null, loading: false });
      });
  }, [i18Next.languages]);
  const { t } = useTranslation();
  const card =(e)=> (
    <div className="competitions__card details">
      <div className="competitions__card--img">
        <img src={`${e.PrimaryImageURL}`} alt="card" />
      </div>
      <div className="competitions__card--content details">
      <Link
                  to={`/web/ecopon/voucherdetails/${e.VoucherID}}`}
                
                  className="btn btn-link pd-0"
                >
                 
        <h1>{e.ServiceName}</h1>
                </Link>
        <h4 className="price">
        {/* <span className="main"  dangerouslySetInnerHTML={{ __html: e.Description }}> */}
                      {/* 400 <span>{t("phase5.card.coin")} </span> */}
                    {/* </span> */}
              
          {/* <span className="main">
            400 <span>{t("phase5.card.coin")} </span>
          </span>
          <span className="text">{t("phase5.card.instead")} </span>
          <span className="red">
            500 <span>{t("phase5.card.coin")}</span>
          </span> */}
        </h4>
      </div>
    </div>
  );
  return (
    <>
      <Breadcrumb
        routes={[
          {
            name: "Vouchers",
            rtlName: " اصناف القسائم",
            linkTo: "/web/ecopon/vouchers",
          },
          {
            name: `${categoryName || "List"}`,
            rtlName: `${
              categoryName || "القائمة"
            }`,
            linkTo: `/web/ecopon/vouchers`,
            // linkTo: `/web/ecopon/vouchers/${categoryId}/${categoryName}`,
            active: true,
          },
        ]}
      />

      <div className="competitions">
        {vouchersListings.loading ? (
          <Loading />
        ) : vouchersListings.data ? (
          <div className="row">
            <div className="competitions__row ">
              {vouchersListings.data.map((e, i) => (
                <div
                 
                  key={i}
                  className="competitions__row--col "
                  
                >
                  {card(e)}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="row">
            <p>{t("phase3.error")}</p>
          </div>
        )}
      </div>
    </>
  );
}
