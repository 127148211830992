import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import Select from "../../components/Select";
import { GenerateOTP, VerifyOTP } from "../../actions/authActions";
import Loading from "../../components/Loading";

export default function CodeForm(props) {
  const [loading, setloading] = React.useState(false);
  const [submitCode, setsubmitCode] = React.useState({
    loading: false,
    error: "",
  });
  const [code0, setcode0] = React.useState("");
  const [code1, setcode1] = React.useState("");
  const [code2, setcode2] = React.useState("");
  const [code3, setcode3] = React.useState("");
  const [error, seterror] = React.useState(false);
  const [success, setsuccess] = React.useState(false);
  const [minute, setminute] = React.useState(59);
  const [params, setparams] = React.useState({});
  const ref0 = React.useRef(null);
  const ref1 = React.useRef(null);
  const ref2 = React.useRef(null);
  const ref3 = React.useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const disabled = () => {
    return !code0 || !code1 || !code2 || !code3 || submitCode.loading;
  };

  //events
  const onchange = (e, ind) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (
      (e.target.value === "" || re.test(e.target.value)) &&
      e.target.value.length <= 1
    ) {
      setsubmitCode({ ...submitCode, error: "" });

      if (ind === 0) {
        setcode0(e.target.value);
        e.target.value !== "" && ref1.current.focus();
      }
      if (ind === 1) {
        setcode1(e.target.value);
        e.target.value !== "" && ref2.current.focus();
      }
      if (ind === 2) {
        setcode2(e.target.value);
        e.target.value !== "" && ref3.current.focus();
      }
      if (ind === 3) {
        setcode3(e.target.value);
        e.target.value !== "" && ref0.current.focus();
      }
    }
  };
  const resendOtp = () => {
    if (minute <= 0) {
      // OtpID: 813953
      let data = {
        Mobile: params.MobileNumber,
        EmailID: "",
        OTPTypeID: location.state.revert ? 4 : 2,
        CountryID: params.CountryID,
      };

      dispatch(GenerateOTP(data));
    }
  };
  const onclick = async (e) => {
    if (!disabled()) {
      setsubmitCode({ error: "", loading: true });
      let data = {
        OTPTypeID:  1,
        OTP: parseInt(`${code0}${code1}${code2}${code3}`),
        OTPID: params.OtpID,
        Mobile: params.MobileNumber,
        EmailID: "",
       
        CountryID: params.CountryID,
      };
      let _forgotPasswordParams = {
        "OTPTypeID": 3,
        "OTPID": params.OTPID,
        "OTP": parseInt(`${code0}${code1}${code2}${code3}`),
        "Mobile":params.Mobile,
        "EmailID":params.EmailID,
       
        "CountryID":params.CountryID
    
      };
       
      try {
        const res = await dispatch(VerifyOTP(location.state.revert ? _forgotPasswordParams:data));
        setsubmitCode({ error: "", loading: false });
    

        if (res.Status === 1) {
          location.state?.third
            ? props.history.push({
                pathname: "/web/auth/existing-data",
                state: {
                  data: params,
                },
              })
            : location.state?.revert ? 
            props.history.push({
              pathname: "/web/auth/recreate",
              state: location.state
            })
            : props.history.push({
                pathname: "/web/auth/register",
                state: {
                  mobile: params.MobileNumber,
                },
              });
        } else {
          setsubmitCode({ error: t("auth.NotMatched"), loading: false });
        }
      } catch (e) {
        setsubmitCode({ error: t("forms.error"), loading: false });
      }
    }
    // setTimeout(() => {
    //   props.history
    //     ? props.history.push("/web/auth/password")
    //     : props.clickAction();
    // }, 2000);
  };

  // cycles
  React.useEffect(() => {
    if (location.state) {
      setparams(location.state.data);
    }
  }, []);
  // React.useEffect(() => {
  //   if (code0 && code1 && code2 && code3) {
  //       setsuccess(true);

  //     // if (code0 == code1 && code0 == code2 && code0 == code3) {
  //     //   seterror(false);
  //     //   setsuccess(true);
  //     // } else {
  //     //   seterror(true);
  //     //   setsuccess(false);
  //     //   setcode0("");
  //     //   setcode1("");
  //     //   setcode2("");
  //     //   setcode3("");
  //     // }
  //   } if (code0 || code1 || code2 || code3) {
  //     // seterror(false);
  //     setsubmitCode({...submitCode,error:''})
  //   }
  // }, [code0, code1, code2, code3]);
  const timer = () => setminute(minute - 1);

  React.useEffect(() => {
    if (minute <= 0) {
      return;
    }
    const id = setInterval(timer, 200);
    return () => clearInterval(id);
  }, [minute]);
  React.useEffect(() => {
    ref0.current.focus();
  }, []);
  const { t } = useTranslation();
  const onChange = (e) => {};
  return (
    <div className={`code-form  ${props.small ? "small" : ""}`}>
      <h1 className="auth__title">{t("auth.code")}</h1>
      <h3 className="auth__subtitle has-sub">
        {t("auth.sent-to")} {params.MobileNumber} (
        <Link to="/web/auth/login-user" className="btn btn-text btn-text--dark">
          {" "}
          {t("auth.edit")}{" "}
        </Link>
        ){" "}
      </h3>
      <h3 className="auth__subtitle  is-sub">
        {t("auth.code-will-arrive")}{" "}
        <span>00:{minute < 10 ? `0${minute}` : minute}</span>
      </h3>

      <div className="auth__box">
        {loading ? (
          <div className=" dots loading">
            <span className="dot _1"></span>
            <span className="dot _2 active"></span>
            <span className="dot _3"></span>
          </div>
        ) : (
          <div className="form__group md-btm">
            <div className="row row--no-padding flex-center reverse">
              <div className=" pos-rel">
                <input
                  onChange={(e) => onchange(e, 0)}
                  value={code0}
                  ref={ref0}
                  type="text"
                  className={`form__input code  ${
                    submitCode.error
                      ? "code__error"
                      : code0
                      ? "code__success"
                      : ""
                  }`}
                />
                {submitCode.error && (
                  <span className="form__input--error-dot"></span>
                )}
              </div>

              <div className=" pos-rel">
                <input
                  onChange={(e) => onchange(e, 1)}
                  value={code1}
                  ref={ref1}
                  type="text"
                  className={`form__input code  ${
                    submitCode.error
                      ? "code__error"
                      : code1
                      ? "code__success"
                      : ""
                  }`}
                />
                {submitCode.error && (
                  <span className="form__input--error-dot"></span>
                )}
              </div>
              <div className=" pos-rel">
                <input
                  onChange={(e) => onchange(e, 2)}
                  value={code2}
                  ref={ref2}
                  type="text"
                  className={`form__input code  ${
                    submitCode.error
                      ? "code__error"
                      : code2
                      ? "code__success"
                      : ""
                  }`}
                />
                {submitCode.error && (
                  <span className="form__input--error-dot"></span>
                )}
              </div>
              <div className=" pos-rel">
                <input
                  onChange={(e) => onchange(e, 3)}
                  value={code3}
                  ref={ref3}
                  type="text"
                  className={`form__input code  ${
                    submitCode.error
                      ? "code__error"
                      : code3
                      ? "code__success"
                      : ""
                  }`}
                />
                {submitCode.error && (
                  <span className="form__input--error-dot"></span>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="form__group md-btm">
          <button
            onClick={onclick}
            className={`  btn bold custom-padding custom-padding ${
              disabled() ? "btn--disabled" : "btn--green"
            } w-100`}
          >
            {submitCode.loading ? (
              <Loading white smMargin />
            ) : (
              t("placeholders.check")
            )}{" "}
          </button>
        </div>
        {submitCode.error && <p className="error-text">{submitCode.error}</p>}
        <div className="form__footer pd-top">
          <div className="flex-between wrap_center">
            <div className=" text-right   col-padding-small flex-center">
              <span>{t("auth.code-hasnt-arrive")}</span>
              <img
                src={`${process.env.PUBLIC_URL}/img/face.png`}
                width="20px"
                alt="face"
              />
            </div>
            <div className=" text-left  col-padding-small">
              <a
                onClick={resendOtp}
                className={`btn btn-text hovered  ${
                  minute <= 0 ? "" : "disabled"
                }`}
              >
                {t("auth.code-resend")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
