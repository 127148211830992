import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOGIN_INIT,

} from "./../actions/types";
let INITIAL_STATE = {
  isAuth: false,
  errors: [],
  userData: {},
  isLoading: false,
};
export const authReducer = (state = INITIAL_STATE, action) => {
   
  switch (action.type) {
    case LOGIN_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        isLoading: false,
        errors: [],
        userData: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuth: false,
        isLoading: false,
        errors: action.payload,
        userData: {},
      };

      case LOGOUT:
        return {
          ...state,
          isAuth: false,
          isLoading: false,
          errors: [],
          userData: {},
        };
      
    default:
      return state;
  }
};
