import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import i18Next from "i18next";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  GetCoponInformation,
  GetUserTerms,
  SaveECoupons,
} from "../../../actions/generalActions";
import Loading from "../../../components/Loading";
import useWindowDimensions from "../../../helpers/JsMediaQuery";
import Breadcrumb from "../../../components/Breadcrumb";
import { LOGIN_REDIRECTION_URL } from "../../../actions/types";
import Modal from "../../../components/Modal";
import CodeCheck from "./CodeCheck";
import ExternalUrl from "./ExternalUrl";

export default function SingleChallenge(props) {
  let { coponId, serviceId } = useParams();

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAuth, userData } = useSelector((state) => state.auth);

  const [compsDetails, setcompsDetails] = React.useState({
    loading: false,
    data: null,
  });
  const [subsription, setsubsription] = React.useState({
    loading: false,
    error: "",
  });
  const [redirect, setredirect] = React.useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [externalUrl, setexternalUrl] = React.useState(false);
  const [NotAllowedModalIsOpen, setNotAllowedModalIsOpen] = React.useState(false);

  function openNotAllowedModal() {
    setNotAllowedModalIsOpen(true);
  }

  function closeNotAllowedModal() {
    setNotAllowedModalIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const onSubscripeClick = () => {
    if (isAuth) {
      setredirect(false);
      if(compsDetails.data.AllowParticipation){
        if (compsDetails.data.EntryTypeID === 41) {
          // setsubsription({ error: "", loading: true });
  
          // dispatch(GetUserTerms(coponId))
          //   .then((e) => {
          //     setuserTerms({ ...userTerms, text: e.EcouponDetails[0]?.UsesTerm });
          //     setsubsription({ error: "", loading: false });
          //     setIsOpen(true);
          //   })
          //   .catch((err) => {
          //     setsubsription({ error: t("phase3.error"), loading: false });
          //   });
          setIsOpen(true);
        } else if (
          compsDetails.data.EntryTypeID === 47 ||
          compsDetails.data.EntryTypeID === 48
        ) {
          // setsubsription({ error: "", loading: true });
  
          // dispatch(GetUserTerms(coponId))
          //   .then((e) => {
          //     setuserTerms({ ...userTerms, text: e.EcouponDetails[0]?.UsesTerm });
          //     setsubsription({ error: "", loading: false });
          //     setIsOpen(true);
          //   })
          //   .catch((err) => {
          //     setsubsription({ error: t("phase3.error"), loading: false });
          //   });
          setexternalUrl(true);
        }
      } else{
        openNotAllowedModal(true)
      }
     
     
    } else {
      setredirect(true);
      setTimeout(() => {
        sessionStorage.removeItem(LOGIN_REDIRECTION_URL);
        sessionStorage.setItem(LOGIN_REDIRECTION_URL, location.pathname);
        history.push("/web/auth/login-user");
      }, 2000);
    }
  };
  React.useEffect(() => {
    setcompsDetails({ ...compsDetails, loading: true });


    // call compsDetails
    dispatch(GetCoponInformation(serviceId, coponId))
      .then((e) => {
        setcompsDetails({ data: e.eCopon[0] || null, loading: false });

      })
      .catch((err) => {
        setcompsDetails({ data: null, loading: false });
      });
  }, [i18Next.languages]);
  const { height, width } = useWindowDimensions();

  return (
    <div className="">
       <Modal
        closeModal={closeNotAllowedModal}
        openModal={openNotAllowedModal}
        modalIsOpen={NotAllowedModalIsOpen}
        title={""}
      >
        <div className="new-account-form message">
          <div className="message-box">
            <img
              src={`${process.env.PUBLIC_URL}/img/error.svg`}
              alt="message"
            />
            <p>{t("phase4.participation_not_allowed")}</p>
            <a onClick={closeNotAllowedModal} className="btn btn--green w-100">
              {t("phase4.btn_agree")}
            </a>
          </div>
        </div>
      </Modal>
    
      <Breadcrumb
        routes={[
          {
            name: "Competitions",
            rtlName: "المسابقات",
            linkTo: "/web/ecopon/competitions",
          },
          {
            name: `${compsDetails?.data?.ServiceName || "Details"}`,
            rtlName: `${compsDetails?.data?.ServiceName || "تفاصيل المسابقة"}`,
            linkTo: `/web/ecopon/competitions/ ${coponId}/${serviceId}`,
            active: true,
          },
        ]}
      />
      {compsDetails.loading ? (
        <Loading />
      ) : compsDetails.data ? (
        <div className="row">
          <CodeCheck
            modalIsOpen={modalIsOpen}
            userData={userData}
            openModal={openModal}
            closeModal={closeModal}
          />
          <ExternalUrl
            modalIsOpen={externalUrl}
            userData={userData}
            openModal={() => setexternalUrl(true)}
            closeModal={() => setexternalUrl(false)}
          />
          <div className="comp-details big">
            <div className="comp-details__body">
              <div className="comp-details__body--data">
                <div className="comp-details__body--img">
                  <img src={`${compsDetails.data.ImageFile}`} alt="" />
                </div>
                <div className="comp-details__body--text">
                  <h1>{compsDetails.data.ServiceName}</h1>
                  {/* <p>
                    {t("phase3.notify.comp_name")}:{" "}
                    <strong>{compsDetails.data.ServiceName}</strong>
                  </p> */}
                  <p>
                    {t("phase3.notify.comp_date")}:{" "}
                    <strong>{compsDetails.data.StartDate}</strong>
                  </p>
                  <p>
                    {t("phase3.notify.comp_end")}:{" "}
                    <strong className="red">{compsDetails.data.EndDate}</strong>
                  </p>
                  <p>
                    {t("phase3.notify.awards")}:{" "}
                    <strong className="main">
                      {compsDetails.data.PrizeCount} {t("phase5.card.price")}
                    </strong>
                  </p>
                  <p>
                    {t("phase3.notify.whithdraw")}:{" "}
                    <strong>{compsDetails.data.DrawsCount}</strong>
                  </p>
                  <p>{t("phase3.notify.desc_title")}: </p>
                  <p className="long">
                    <strong className="" dangerouslySetInnerHTML={{__html:compsDetails.data.UsesTerm}}></strong>
                  </p>
                  <a
                    className={` btn middle  btn--green
           
             `}
                    onClick={onSubscripeClick}
                  >
                    {subsription.loading ? (
                      <Loading smMargin white={true} />
                    ) : (
                      t("placeholders.subscribe")
                    )}{" "}
                  </a>
                  {redirect && (
                    <p className="error-text mg-top">
                      {t("phase3.redirecting")}
                    </p>
                  )}
                  {subsription.error && (
                    <p className="error-text mg-top">{subsription.error}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="withdraws">
            {compsDetails.data.DrawsDetails.map((e, i) => (
              <div key={i} className="flex-between withdraws__card ">
                <div className="number flex-center">{i + 1}</div>
                <div className="content">
                  <h1>{e.DrawName}</h1>
                  <p>{e.DrawDate}</p>
                </div>
                {e.WinnerDetails.length > 1 && (
                  <a
                    onClick={(event) => {
                      history.push({
                        pathname: "/web/ecopon/competitions-results",
                        state: { data: e,number:i+1},
                      });
                    }}
                    className="btn btn--yellow has-md-radius"
                  >
                    {t("phase5.whithdraw.btn")}
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="row">
          <p>{t("phase3.error")}</p>
        </div>
      )}
    </div>
  );
}
