import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import i18Next from "i18next";

import Tabs from "../../../components/Tabs";
import {
  GetCoponInformation,
  GetVoucherListing,
  getStats,
} from "../../../actions/generalActions";
import Loading from "../../../components/Loading";

export default function Home() {
  const [comps, setcomps] = React.useState({
    loading: false,
    list: [],
  });
  const [vouchers, setvouchers] = React.useState({
    loading: false,
    list: [],
  });
  const [stats, setstats] = React.useState({});
  const { t } = useTranslation();

  const dispatch = useDispatch();
  React.useEffect(() => {
    setcomps({ ...comps, loading: true });
    setvouchers({ ...vouchers, loading: true });

    // call comps
    dispatch(GetCoponInformation(0, 0, 15))
      .then((e) => {
        setcomps({ list: e.eCopon.slice(0, 15), loading: false });

      })
      .catch((err) => {
        setcomps({ list: [], loading: false });
      });
    //call vouchers
    dispatch(GetVoucherListing())
      .then((e) => {
        setvouchers({ list: e.ResponseVoucherListing, loading: false });

      })
      .catch((err) => {
        setvouchers({ list: [], loading: false });
      });

    //call stats
    dispatch(getStats())
      .then((e) => {
         
        setstats({ ...e });
      })
      .catch((err) => {
        setstats({});
      });
  }, [i18Next.languages]);
  //swiper settings for comps and competitions slider
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 5,
    centerPadding: "40px",
    arrows: false,
    autoplay: true,

    slidesToScroll: 1,

    centerMode: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1404,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  //swiper settings for testimonial slider

  const settings_test = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    autoplay: true,
    arrows: false,

    slidesToScroll: 1,
    appendDots: (dots) => (
      <div>
        <ul className="custom-dots" style={{ margin: "0px" }}>
          {" "}
          {dots}{" "}
        </ul>
      </div>
    ),
    customPaging: (i) => <div className="custom-dot"></div>,

    swipeToSlide: true,
  };
  //swiper settings for clients slider

  const settings_clients = {
    slidesToShow: 11,
    centerMode: true,

    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,

    infinite: true,

    slidesToScroll: 1,

    speed: 2000,

    responsive: [
      {
        breakpoint: 1404,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const card_copon = (
    <div className="competitions__card details">
      <div className="competitions__card--img big">
        <img src={`${process.env.PUBLIC_URL}/img/sympol.svg`} alt="card" />
      </div>
      <div className="competitions__card--content details">
        <h1 className="">{t("phase5.card.title2")}</h1>
        <h4 className="price ">
          <span className="main">
            400 <span>{t("phase5.card.coin")} </span>
          </span>
          <span className="text">{t("phase5.card.instead")} </span>
          <span className="red">
            500 <span>{t("phase5.card.coin")}</span>
          </span>
        </h4>
      </div>
    </div>
  );
  const comps_tab = (
    <div>
      {comps.loading ? (
        <Loading white={true} />
      ) : (
        <Slider {...settings}>
          {comps.list.map((e, i) => (
            <div key={i} className="slide-wrapper slide-padding">
              <div className="competitions__card">
              { e.CompetitionStatus ==='2' &&   <div className="competitions__card--badge end">
          <span>{t("phase4.comp.completed")}</span>
        </div>}
        { e.CompetitionStatus ==='3' &&   <div className="competitions__card--badge soon">
          <span>{t("phase4.comp.soon")}</span>
        </div>} 
                <div className="competitions__card--img big">
                  <img src={`${e.ImageFile}`} alt={`${e.ServiceName}`} />
                </div>
                <div className="competitions__card--content">
                  <Link
                    to={`/web/ecopon/competitions/ ${e.ECouponID}/${e.EServiceID}`}
                    className="btn btn-link pd-0"
                  >
                    <h1 className="">{e.ServiceName}</h1>
                  </Link>
                  <h4 className="price ">
                    {e.PrizeCount} <span>{t("phase5.card.price")}</span>
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
  const vouchers_tab = (
    <div>
      {vouchers.loading ? (
        <Loading white={true} />
      ) : (
        <Slider {...settings}>
          {vouchers.list.map((e, i) => (
            <div key={i} className="slide-wrapper slide-padding">
              <div className="competitions__card details">
           
                <div className="competitions__card--img big">
                  <img src={`${e.PrimaryImageURL}`} alt="card" />
                </div>
                <div className="competitions__card--content details">
                  <Link
                    to={`/web/ecopon/voucherdetails/ ${e.VoucherID}`}
                    className="btn btn-link pd-0"
                  >
                    <h1 className="">{e.ServiceName}</h1>
                  </Link>
                  <h4 className="price ">
                    <span
                      className="main"
                      dangerouslySetInnerHTML={{ __html: null }}
                    >
                      {/* 400 <span>{t("phase5.card.coin")} </span> */}
                    </span>
                    {/* <span className="text">{t("phase5.card.instead")} </span>
                    <span className="red">
                      500 <span>{t("phase5.card.coin")}</span>
                    </span> */}
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
  return (
    <div>
      
      <div className="home-about">
        <div className="row">
          <div className="flex-center wrapper">
            <img src={`${process.env.PUBLIC_URL}/img/about.svg`} alt="about" />
            <div className="home-about__content">
              <h2 className="has-line">{t("about.header")}</h2>
              <p>{t("footer.text")}</p>
              <p>{t("about.body")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="home-slider">
        <Tabs
          tabs={[
            {
              name: t("home.tabs.tab1"),
              content: comps_tab,
              linkTo: "/web/ecopon/competitions",
            },
            {
              name: t("home.tabs.tab2"),
              content: vouchers_tab,
              linkTo: "/web/ecopon/vouchers",
            },
          ]}
        />
      </div>

      <div className="home-why">
        <div className="row">
          <div className="flex-between wrapper">
            <div className="home-why__content">
              <h1 className="has-line">{t("home.why.title")}</h1>
              <p className="sub">{t("home.why.desc")}</p>
              <div className="home-why__list">
                <div className="home-why__list--item">
                  <div className="img">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/enail_con.svg`}
                      alt="why copon"
                    />
                  </div>
                  <div className="text">
                    <h2>{t("home.why.sub1")}</h2>
                    <p>{t("home.why.desc1")}</p>
                  </div>
                </div>
                <div className="home-why__list--item">
                  <div className="img">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/enail_con.svg`}
                      alt="why copon"
                    />
                  </div>
                  <div className="text">
                    <h2>{t("home.why.sub2")}</h2>
                    <p>{t("home.why.desc1")}</p>
                  </div>
                </div>
                <div className="home-why__list--item">
                  <div className="img">
                    <img
                      src={`${process.env.PUBLIC_URL}/img/enail_con.svg`}
                      alt="why copon"
                    />
                  </div>
                  <div className="text">
                    <h2>{t("home.why.sub3")}</h2>
                    <p>{t("home.why.desc1")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-why__img">
              <img
                src={`${process.env.PUBLIC_URL}/img/why-copon.svg`}
                alt="why copon"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="home-numbers">
        <div className="row">
          <h1 className="has-line ">{t("home.numbers.title")}</h1>
          <div className="home-numbers__list">
            <div className="home-numbers__list--item">
              <div className="img">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon1.svg`}
                  alt="icon"
                />
              </div>
              <div className="text">
                <h3>{t(`home.numbers.sub1`)}</h3>
                <h6>{stats.ValueOfPrizes}</h6>
              </div>
            </div>
            <div className="home-numbers__list--item">
              <div className="img">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon2.svg`}
                  alt="icon"
                />
              </div>
              <div className="text">
                <h3>{t(`home.numbers.sub2`)}</h3>
                <h6>{stats.CompetitionsCompleted}</h6>
              </div>
            </div>
            <div className="home-numbers__list--item">
              <div className="img">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon3.svg`}
                  alt="icon"
                />
              </div>
              <div className="text">
                <h3>{t(`home.numbers.sub3`)}</h3>
                <h6>{stats.TotalWinners}</h6>
              </div>
            </div>
            <div className="home-numbers__list--item">
              <div className="img">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon4.svg`}
                  alt="icon"
                />
              </div>
              <div className="text">
                <h3>{t(`home.numbers.sub4`)}</h3>
                <h6>{stats.CompetitionParticipations}</h6>
              </div>
            </div>
            <div className="home-numbers__list--item">
              <div className="img">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon5.svg`}
                  alt="icon"
                />
              </div>
              <div className="text">
                <h3>{t(`home.numbers.sub5`)}</h3>
                <h6>{stats.RegisteredCorporates}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="testmonials">
        <div className="row">
          <h1 className="has-line white">{t("home.test.title")}</h1>
          <div className="testmonials__content">
            <Slider {...settings_test}>
              {[0, 0, 0, 0, 0].map((e, i) => (
                <div key={i} className="slide-wrapper ">
                  <div className="testmonial">
                    <div className="text">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/top-quote.svg`}
                        alt="icon"
                        className="quote-top"
                      />
                      <p>{t("home.test.desc")}</p>
                      <img
                        src={`${process.env.PUBLIC_URL}/img/bottom-quote.svg`}
                        alt="icon"
                        className="quote-bottom"
                      />
                    </div>
                    <h2>{t("home.test.name")}</h2>
                    <h4>{t("home.test.job")}</h4>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="clients">
        <div className="row">
          <h1 className="has-line white">{t("home.clients.title")}</h1>
          <div className="clients__list">
            <Slider {...settings_clients}>
              {[1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3].map((e, i) => (
                <div key={i} className="slide-wrapper ">
                  <img
                    className="sponsor"
                    src={`${process.env.PUBLIC_URL}/img/client${e}.png`}
                    alt="icon"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="home-info">
        <div className="row">
          <div className="about__detail ">
            <div className="wrapper">
              <h1 className="about__detail--title has-line">
                {t("home.contact")}
              </h1>
            </div>
            <div className="about__detail--boxes">
              <div className="about__detail--box">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/enail_con-main.svg`}
                    alt=""
                  />
                </div>
                <div className="content">
                  <h1>{t("contact.for_suggest")}</h1>
                  <a href="" className="btn btn-text btn-text--black">
                    info@e-copon.com
                  </a>
                </div>
              </div>

              <div className="about__detail--box">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/helps-main.svg`}
                    alt=""
                  />
                </div>
                <div className="content">
                  <h1>{t("contact.for_support")}</h1>
                  <a href="" className="btn btn-text btn-text--black">
                    support@e-copon.com
                  </a>
                </div>
              </div>
              <div className="about__detail--box">
                <div className="icon">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/whatsapp_bussnis-main.svg`}
                    alt=""
                  />
                </div>
                <div className="content">
                  <h1>{t("contact.for_whatsapp")}</h1>
                  <a href="" className="btn btn-text btn-text--black">
                    00556849541646498
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
