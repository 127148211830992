import React from "react";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { GetUserTerms, SaveECoupons } from "../../../actions/generalActions";
import Modal from "../../../components/Modal";
import Loading from "../../../components/Loading";
import { GetNotifications } from "../../../actions/generalActions";


export default function ExternalUrl({
  modalIsOpen,
  openModal,
  closeModal,
  userData,
}) {
  const dispatch = useDispatch();
  let { coponId, serviceId } = useParams();

  const [text, setText] = React.useState("");
  const [checked, setchecked] = React.useState(false);
  const [userTermsModalOpen, setuserTermsModalOpen] = React.useState(false);
  const [successModelOpen, setsuccessModelOpen] = React.useState(false);
  const [failureModelOpen, setfailureModelOpen] = React.useState(false);

  const { t } = useTranslation();
  const [userTerms, setuserTerms] = React.useState({
    loading: false,
    text: "",
  });
  const [submit, setsubmit] = React.useState({
    loading: false,
    error: "",
  });

  const disabled = () => !checked || !text;

  //events
  const onCodeCheckClick = () => {
    if (!disabled()) {
      setsubmit({ error: "", loading: true });
      let visitorData = JSON.parse(localStorage.getItem("visitor"));

      let data = {
        Barcode: text,
        ConsumerID: userData.ConsumerID,
        EcouponID: coponId,
        WrongAnswer: 0,
        EServiceID: serviceId,
        Latitude: visitorData.latitude,
        Longitude: visitorData.longitude,
        MacAddress: visitorData.ip,
      };

      dispatch(SaveECoupons(data))
        .then((e) => {
           
          setsubmit({ loading: false, error: "" });
          
          switch (e.Success) {
            case 1:
              setsubmit({ loading: false, error: "" });
              closeModal()
              setsuccessModelOpen(true)
              break;
            case 0:
              setsubmit({ loading: false, error: e.Message });

              break;

            default:
              break;
          }
        })
        .catch((err) => {
          setsubmit({ error: t("phase3.error"), loading: false });
        });
    }
  };
  const onUserTermsAgree = () => {
 
    setsubmit({ error: "", loading: true });
      let visitorData = JSON.parse(localStorage.getItem("visitor"));

      let data = {
        Barcode: text,
        ConsumerID: userData.ConsumerID,
        EcouponID: coponId,
        WrongAnswer: 0,
        EServiceID: serviceId,
        Latitude: visitorData.latitude,
        Longitude: visitorData.longitude,
        MacAddress: visitorData.ip,
      };

      dispatch(SaveECoupons(data))
        .then((e) => {
           
          setsubmit({ loading: false, error: "" });
          
          switch (e.Success) {
            case 1:
              setsubmit({ loading: false, error: "" });
              closeModal()
              setsuccessModelOpen(true)
              dispatch(GetNotifications(userData.ConsumerID))
              setTimeout(() => {
                  window.open(e.ExternalRedirectionURL)
              setsuccessModelOpen(false)

              }, 2000);
              break;
            case 0:
              setsubmit({ loading: false, error: e.Message });
              setTimeout(() => {
                setfailureModelOpen(true)


            }, 100);
              break;

            default:
              break;
          }
        })
        .catch((err) => {
          setsubmit({ error: t("phase3.error"), loading: false });
        });
  };
  const onUserTermsCancel = () => {
  
    closeModal(false);
  };
 
  React.useEffect(() => {
    setuserTerms({ text: "", loading: true });

    dispatch(GetUserTerms(coponId))
      .then((e) => {
        setuserTerms({ loading: false, text: e.EcouponDetails[0]?.UsesTerm });
      })
      .catch((err) => {
        setuserTerms({ text: t("phase3.error"), loading: false });
      });
  }, [])
  return (
    <div className="">
        <Modal
       closeModal={closeModal}
       openModal={openModal}
       modalIsOpen={modalIsOpen}
        title={t("footer.rules")}
      >
        <div className="new-account-form ">
          <div className="policy">
            <div className="policy__content">
              {userTerms.loading ? (
                <Loading />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: userTerms.text }}></p>
              )}
            </div>
            <div className="policy__btns">
              <a onClick={onUserTermsAgree} className="btn btn--yellow first">
            
                {submit.loading ? (
                  <Loading white smMargin />
                ) : (
                    t("phase4.btn_agree")
                )}{" "}
              </a>
              <a
                onClick={onUserTermsCancel}
                className="btn btn--disabled reject"
              >
                {t("phase4.cancel")}
              </a>
            </div>
          </div>
        </div>
      </Modal>
           <Modal
     closeModal={()=>setfailureModelOpen(false)}
     openModal={()=>setfailureModelOpen(true)}
     modalIsOpen={failureModelOpen}
        title={t("phase4.reject")}
      >
        <div className="new-account-form message">
          <div className="message-box">
            <img
              src={`${process.env.PUBLIC_URL}/img/error.svg`}
              alt="message"
            />
            <p>{submit.error}</p>
            <a onClick={()=>setfailureModelOpen(false)} className="btn btn--green w-100">
              {t("phase4.btn_agree")}
            </a>
          </div>
        </div>
      </Modal>
   
       <Modal
        closeModal={()=>setsuccessModelOpen(false)}
        openModal={()=>setsuccessModelOpen(true)}
        modalIsOpen={successModelOpen}
        title={t("phase4.success")}
      >
        <div className="new-account-form message">
          <div className="message-box">
            <img
              src={`${process.env.PUBLIC_URL}/img/check.svg`}
              alt="message"
            />
            <p>{t("phase4.success_message")}</p>
            <a onClick={()=>setsuccessModelOpen(false)} className="btn btn--green w-100">
              {t("phase4.redirecting")}
            </a>
          </div>
        </div>
      </Modal>
    
    </div>
  );
}
