import React from "react";
import { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

import authService from "../services/auth-service";

export default (props) => {
  const { component: Component, props: componentProps, ...rest } = props;
  const { state } = { ...props.location };
  const history = useHistory();

  return (
    <Route
      {...rest}
      render={(props) =>
        authService.isAuthenticated() ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          <Component {...props} {...componentProps} />
        )
      }
    />
  );
};
