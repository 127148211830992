import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useLocation } from "react-router-dom";
import authService from "../services/auth-service";
import { LOGIN_REDIRECTION_URL } from "../actions/types";



export default props => {
  const { component: Component, ...rest } = props;
  const location = useLocation();
  //remove redir route when hitting protected
  React.useEffect(() => {
    sessionStorage.removeItem(LOGIN_REDIRECTION_URL)
    sessionStorage.setItem(LOGIN_REDIRECTION_URL,location.pathname)
  }, [])
  return (
    <Route
      {...rest}
      render={props =>
        authService.isAuthenticated() ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={{ pathname: `/web/auth/login-user` }} />
        )
      }
    />
  );
};
