import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import i18Next from "i18next";

import DatePicker from "../../components/DatePicker";
import PhoneInput from "../../components/NumberInput";
import Select from "../../components/Select";
import EmailInput from "../../components/EmailInput";
import { GetConsumer, UpdateConsumerProfile } from "../../actions/authActions";
import {
  GetCountries,
  GetNationality,
  GetUserIdentityTypeList,
  GetCities,
} from "../../actions/generalActions";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";

export default function MyData() {
  const [updateSubmitInfo, setupdateSubmitInfo] = React.useState({
    loading: false,
    error: "",
  });
  const [identityDisabled, setIdentityDisabled] = React.useState(false);
  const [formData, setformData] = React.useState({
    loading: false,
    error: "",
    data: {
      CityID: 0,
      ConsumerID: 0,
      CountryID: 0,
      Email: "",
      FullName: "",
      Gender: "",
      Mobile: "",
      NationalityID: 0,
      UserIdentityNumber: "",
      UserIdentityTypeID: 0,
    },
  });
  const [countries, setcountries] = React.useState({
    loading: false,
    list: [],
  });
  const [cities, setcities] = React.useState({
    loading: false,
    list: [],
  });
  const [nationalities, setnationalities] = React.useState({
    loading: false,
    list: [],
  });
  const [nationalitiesTypes, setnationalitiesTypes] = React.useState({
    loading: false,
    list: [],
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const { isAuth, userData } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const genders = [
    { value: "1", label: `${t("forms.male")}` },
    { value: "2", label: `${t("forms.female")}` },
  ];
  const options2 = [
    { value: "1", label: "فرغ" },
    { value: "2", label: "السحب الثاني" },
    { value: "3", label: "السحب الثالث" },
    { value: "4", label: "السحب الرابع" },

    { value: "5", label: "السحب الخامس" },
  ];
  const dispatch = useDispatch();

  const findInArr = (arr = [], value, property) => {
     
    let ind = arr.findIndex((e) => e[property] === value);
    return ind !== -1 ? arr[ind] : [];
  };

  const callDropdownLists = () => {
    //call countries
    setcountries({ ...countries, loading: true });

    setcities({ ...cities, list: [] });

    dispatch(GetCountries())
      .then((e) => {
        setcountries({ list: e, loading: false });
        setcities({ ...cities, loading: true });

        // call first drop down
        dispatch(GetCities(formData.data.CountryID))
          .then((res) => {
            setcities({ list: res, loading: false });
          })
          .catch((err) => {
            setcities({ list: [], loading: false });
          });
      })
      .catch((err) => {
        setcountries({ list: [], loading: false });
      });

    // call nationalities
    setnationalities({ ...nationalities, loading: true });
    dispatch(GetNationality())
      .then((e) => {
        setnationalities({ list: e, loading: false });
      })
      .catch((err) => {
        setnationalities({ list: [], loading: false });
      });

    // call nationalitiesTypes
    setnationalitiesTypes({ ...nationalitiesTypes, loading: true });
    dispatch(GetUserIdentityTypeList())
      .then((e) => {
        setnationalitiesTypes({ list: e, loading: false });
      })
      .catch((err) => {
        setnationalitiesTypes({ list: [], loading: false });
      });
  };

  // events
  const onUpdateSubmit = (e) => {
    setupdateSubmitInfo({ ...updateSubmitInfo, loading: true });

    dispatch(UpdateConsumerProfile(formData.data))
      .then((res) => {
        setupdateSubmitInfo({ error: "", loading: false });

        switch (res?.Success) {
          case 1:
            setIsOpen(true);
            break;
          case 0:
            setupdateSubmitInfo({ error: res.Message, loading: false });

            break;

          default:
            break;
        }
      })
      .catch((err) => {
        setupdateSubmitInfo({ error: t("forms.error"), loading: false });
      });
  };
  const onCountryChange = (e) => {
    setcities({ ...cities, loading: true });
    updateFormData(e.CountryID, "CountryID");

    // call first drop down
    dispatch(GetCities(e.CountryID))
      .then((res) => {
        setcities({ list: res, loading: false });
      })
      .catch((err) => {
        setcities({ list: [], loading: false });
      });
  };

  const updateFormData = (value, property) => {
     
    let nwData = { ...formData };
    nwData.data[property] = value;
    setformData(nwData);
    
  };
  React.useEffect(() => {
    if (userData.ConsumerID) {
      setformData({ ...formData, loading: true });

      dispatch(GetConsumer(userData.ConsumerID))
        .then((res) => {
          let nwData = { ...formData };
          nwData.loading = false;
          nwData.data["FullName"] = res.FullName;
          nwData.data["CityID"] = res.CityID;
          nwData.data["ConsumerID"] = res.ConsumerID;
          nwData.data["Gender"] = res.Gender;
          nwData.data["CountryID"] = res.CountryID;
          nwData.data["Email"] = res.Email;
          nwData.data["Mobile"] = res.Mobile;
          nwData.data["NationalityID"] = res.NationalityID;
          nwData.data["UserIdentityNumber"] = res.UserIdentityNumber || "";
          nwData.data["UserIdentityTypeID"] = res.UserIdentityTypeID;
        res.UserIdentityNumber &&  setIdentityDisabled(true)
          setformData(nwData);
          callDropdownLists();
        })
        .catch((e) => {
          setformData({
            ...formData,
            loading: false,
            error: t("forms.error"),
          });
        });
    }
  }, [userData, isAuth, i18Next.languages]);
  return (
    <div className="my-data">
      <div className="row">
        <div className="goBack-btn right top hidden-mobile flex">
          <img
            src={`${process.env.PUBLIC_URL}/img/chevron-left_blue.svg`}
            alt="cobon back"
          />

          <Link to="/" className="btn btn--normal btn--normal-blue ">
            {t("auth.back")}
          </Link>
        </div>

        <div className="my-data__box">
          <h1 className="box-title">{t("phase3.personal")}</h1>

          {formData.loading ? (
            <div className="mg-btm">
              <Loading smMargin />
            </div>
          ) : formData.error ? (
            <span className="error-text">{formData.error}</span>
          ) : null}
          <Modal
            closeModal={closeModal}
            openModal={openModal}
            modalIsOpen={modalIsOpen}
            title={t("forms.updated")}
          >
            <div className="new-account-form message">
              <div className="message-box">
                <img
                  src={`${process.env.PUBLIC_URL}/img/check.svg`}
                  alt="message"
                />
                <p>{t("forms.updated")}</p>
                <a onClick={closeModal} className="btn btn--green w-100">
                  {t("phase4.btn_agree")}
                </a>
              </div>
            </div>
          </Modal>

          <form className="my-data__form font-0">
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <input
                    type="text"
                    value={formData.data.FullName}
                    onChange={(e) => updateFormData(e.target.value, "FullName")}
                    className={`form__input   circle `}
                    placeholder={t("placeholders.name")}
                  />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel font-intial">
                  {/* <DatePicker /> */}
                  <Select
                    className="filter-select in-form "
                    classNamePrefix="select"
                    value={findInArr(genders, formData.data?.Gender, "value")}
                    onChange={(e) => {
                      updateFormData(e.value, "Gender");
                    }}
                    placeholder={t("placeholders.sex")}
                    name="gender"
                    options={genders}
                  />
                </div>
              </div>
            </div>
            {/* 
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <input
                    type="text"
                    className={`form__input   circle `}
                    placeholder={t("placeholders.address1")}
                  />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel">
                  <input
                    type="text"
                    className={`form__input   circle `}
                    placeholder={t("placeholders.address2")}
                  />
                </div>
              </div>
            </div>

            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <PhoneInput placeholder={t("placeholders.phone2")} />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel">
                  <input
                    type="text"
                    className={`form__input   circle `}
                    placeholder={t("placeholders.choose")}
                  />
                </div>
              </div>
            </div> */}
            {/* 
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <Select
                    className="filter-select in-form "
                    classNamePrefix="select"
                    onChange={() => {}}
                    placeholder={t("placeholders.sex")}
                    name="sex"
                    options={options2}
                  />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel">
                  <input
                    type="text"
                    className={`form__input   circle `}
                    placeholder={t("placeholders.choose")}
                  />
                </div>
              </div>
            </div> */}

            <div className="form__group md-btm2 display-in-bl">
              <div className="row row--no-padding">
                <div className="col-1-of-3 md-btm col-padding-small pos-rel">
                  <Select
                    className="filter-select in-form "
                    classNamePrefix="select"
                    placeholder={t("placeholders.country")}
                    name="country"
                    onChange={onCountryChange}
                    isSearchable={true}
                    isLoading={countries.loading}
                    options={countries.list}
                    getOptionLabel={(option) => option.CountryName}
                    getOptionValue={(option) => option.CountryID}
                    value={findInArr(
                      countries.list,
                      formData.data?.CountryID,
                      "CountryID"
                    )}
                  />
                </div>
                <div className="col-1-of-3 col-padding-small pos-rel">
                  <Select
                    className="filter-select in-form "
                    classNamePrefix="select"
                    placeholder={t("placeholders.city")}
                    name="city"
                    onChange={(e) => {
                      updateFormData(e.CityID, "CityID");
                    }}
                    isSearchable={true}
                    isLoading={cities.loading}
                    options={cities.list}
                    getOptionLabel={(option) => option.CityName}
                    getOptionValue={(option) => option.CityID}
                    value={findInArr(
                      cities.list,
                      formData.data?.CityID,
                      "CityID"
                    )}
                  />
                </div>
                <div className="col-1-of-3 col-padding-small pos-rel">
                  <Select
                    className="filter-select in-form "
                    classNamePrefix="select"
                    placeholder={t("placeholders.nationality")}
                    name="nationality"
                    onChange={(e) => {
                      updateFormData(e.NationalityID, "NationalityID");
                    }}
                    isSearchable={true}
                    isLoading={nationalities.loading}
                    options={nationalities.list}
                    getOptionLabel={(option) => option.NationalityName}
                    getOptionValue={(option) => option.NationalityID}
                    value={findInArr(
                      nationalities.list,
                      formData.data?.NationalityID,
                      "NationalityID"
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <Select
                    className="filter-select in-form "
                    classNamePrefix="select"
                    placeholder={t("placeholders.card_type")}
                    name="nationalityType"
                    onChange={(e) => {
                      updateFormData(
                        e.UserIdentityTypeID,
                        "UserIdentityTypeID"
                      );
                    }}
                    isSearchable={true}
                    isLoading={nationalitiesTypes.loading}
                    options={nationalitiesTypes.list}
                    getOptionLabel={(option) => option.UserIdentityName}
                    getOptionValue={(option) => option.UserIdentityTypeID}
                    isDisabled={identityDisabled}
                    value={findInArr(
                      nationalitiesTypes.list,
                      formData.data?.UserIdentityTypeID,
                      "UserIdentityTypeID"
                    )}
                  />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel">
                  <PhoneInput
                    placeholder={t("placeholders.card_num")}
                    changeEvent={(e) => {
                      updateFormData(e, "UserIdentityNumber");
                    }}
                    disabled={identityDisabled}
                    value={formData.data?.UserIdentityNumber}
                  />
                </div>
              </div>
            </div>
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <EmailInput
                    placeholder={t("placeholders.email")}
                    changeEvent={(e) => {
                      updateFormData(e.Email, "Email");
                    }}
                    value={formData.data?.Email}
                  />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel">
                  <PhoneInput
                    placeholder={t("placeholders.tele")}
                    changeEvent={(e) => {
                      updateFormData(e, "Mobile");
                    }}
                    value={formData.data.Mobile}
                  />
                </div>
              </div>
            </div>

            <div className="form__group mg-top display-in-bl">
              {updateSubmitInfo.error && (
                <div className="mg-btm">
                  <span className="error-text">{updateSubmitInfo.error}</span>
                </div>
              )}
              <a
                onClick={updateSubmitInfo.loading ? null : onUpdateSubmit}
                className={`btn  
             ${updateSubmitInfo.loading ? "btn--disabled" : "btn--green"}
          w-100`}
              >
                {updateSubmitInfo.loading ? (
                  <Loading white smMargin />
                ) : (
                  t("placeholders.update")
                )}{" "}
              </a>
            </div>
          </form>
        </div>

        {/* <div className="my-data__box mg-top-bigger">
          <h1 className="box-title">{t("forms.request")}</h1>
          <form className="my-data__form font-0">
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <Select
                    className="filter-select in-form "
                    classNamePrefix="select"
                    onChange={() => {}}
                    placeholder={t("placeholders.card_type")}
                    name="id"
                    options={options2}
                  />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel">
                  <PhoneInput placeholder={t("placeholders.card_num")} />
                </div>
              </div>
            </div>
            <div className="form__group md-btm2">
              <div className="row row--no-padding">
                <div className="col-1-of-2 md-btm col-padding-small pos-rel">
                  <EmailInput placeholder={t("placeholders.email")} />
                </div>
                <div className="col-1-of-2 col-padding-small pos-rel">
                  <PhoneInput placeholder={t("placeholders.tele")} />
                </div>
              </div>
            </div>

            <div className="form__group mg-top display-in-bl">
              <Link
                to="/forms/password"
                className={`btn  
             ${text.length < 1 ? "btn--disabled" : "btn--green"}
          w-100`}
              >
                {t("placeholders.request_admin")}{" "}
              </Link>
            </div>
          </form>
        </div> */}

        <div className="goBack-btn center bottom">
          <img
            src={`${process.env.PUBLIC_URL}/img/chevron-left_2.svg`}
            alt="cobon back"
          />

          <Link to="/" className="btn btn--normal  ">
            {t("auth.back")}
          </Link>
        </div>
      </div>
    </div>
  );
}
